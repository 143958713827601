import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import {UserButton} from "./UserButton";
import {MENU_CLOSED, MENU_OPENED, useHeaderDispatch, useHeaderState} from "./headerContext";
import Hidden from "@mui/material/Hidden";
import {useNavigate} from "react-router";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
}));

export const Header = () => {
    const classes = useStyles();
    const {menuOpen} = useHeaderState();
    const dispatch = useHeaderDispatch();
    const navigate = useNavigate()

    const toggleMenu = () => {
        if(menuOpen) {
            dispatch({type: MENU_CLOSED})
        } else {
            dispatch({type: MENU_OPENED})
        }
    }

    return (
        <div className={classes.root}>
            <AppBar
                position="fixed"
                sx={{
                    zIndex: theme => theme.zIndex.drawer + 1
                }}
            >
                <Toolbar>
                    <Hidden smUp>
                        <IconButton
                            edge="start"
                            sx={{
                                mr: 2
                            }}
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleMenu}
                            size="large">
                            <MenuIcon/>
                        </IconButton>
                    </Hidden>
                    <Typography variant="h6" className={classes.title} onClick={() => navigate('/')}>
                        Kurs i Bekymringsmestring
                    </Typography>
                    <Box flexGrow={1}/>
                    <UserButton />
                </Toolbar>
            </AppBar>
        </div>
    );
}