import React from 'react';
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {InstructorsTable} from "./InstructorsTable";
import Paper from "@mui/material/Paper";


export const Admin = () => {
    const isAdmin = useCheckIfAdmin();

    if(!isAdmin) {
        return null;
    }

    return (
        <div>

            <Paper
                sx={{
                    p: {
                        xs: 1,
                        md: 4
                    },
                    mb: 4
                }}
            >
                <InstructorsTable />
            </Paper>
        </div>
    )
}