import {DESELECTED, useFolderDispatch, useFolderState} from "./folderContext";
import {Button, IconButton, Stack, Typography} from "@mui/material";
import {Close} from "@mui/icons-material";
import {distanceAgo} from "./FolderTableRow";
import {useFileContent} from "./useFileContent";
import filesize from "filesize";

export const FileDetails = () => {
    const {
        selected
    } = useFolderState()

    const folderDispatch = useFolderDispatch()
    const {downloadFile} = useFileContent({
        url: selected?.url,
        filename: selected?.fileName || selected?.title,
    })

    const closeHandler = () => {
        folderDispatch({
            type: DESELECTED
        })
    }

    const downloadHandler = () => {
        downloadFile()
    }
    return (
        <>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                sx={{
                    mb: 2
                }}
            >
                <Typography variant={"h6"}>
                    Filinformasjon
                </Typography>
                <IconButton
                    onClick={closeHandler}
                >
                    <Close/>
                </IconButton>

            </Stack>
            <Stack
                alignItems={"flex-start"}
                sx={{
                    mb: 2
                }}
            >
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Typography>
                        Filnavn:
                    </Typography>
                    <Typography noWrap={true}>
                        {selected?.title}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Typography>
                        Størrelse:
                    </Typography>
                    <Typography>
                        {selected?.fileSize ? filesize(selected?.fileSize) : "-"}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Typography>
                        Type:
                    </Typography>
                    <Typography>
                        {selected?.type}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Typography>
                        Opprettet:
                    </Typography>
                    <Typography>
                        {selected?.createdAt ? distanceAgo(selected?.createdAt.toDate()) : '-'}
                    </Typography>
                </Stack>
                <Stack
                    direction={"row"}
                    spacing={1}
                >
                    <Typography>
                        Sist endret:
                    </Typography>
                    <Typography>
                        {selected?.modifiedAt ? distanceAgo(selected?.modifiedAt.toDate()) : '-'}
                    </Typography>
                </Stack>
            </Stack>
            <Button
                variant={"contained"}
                onClick={downloadHandler}
            >
                Last ned filen
            </Button>
        </>
    )
}