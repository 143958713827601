import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {doc, updateDoc, getDoc, setDoc, collection, deleteDoc} from 'firebase/firestore'

export const useArticle = id => {
    const [article, setArticle] = useState({});
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db && id) {
            const instructorsRef = doc(db, 'news', id);
            getDoc(instructorsRef)
            .then(doc => setArticle(doc.exists ? doc.data() : {}))

        } else {
            setArticle({
                text: "",
                media: null,
                title: ""
            });
        }
    }, [db, id, setArticle])

    const updateArticle = (id, updatedArticle) => {
        if(db && id) {
            const newsRef  = doc(db, 'news', id)
            return updateDoc(
                newsRef,
                {
                    ...updatedArticle,
                    published: new Date()
                }
            )
        } else if(db) {
            const newsRef = collection(db, 'news')
            return setDoc(
                doc(newsRef),
                {
                    ...updatedArticle,
                    published: new Date()
                }
            )
        }
    }

    const deleteArticle = id => {
        if(db && id) {
            const newsDoc  = doc(db, 'news', id)
            return deleteDoc(newsDoc)
        }
    }

    return {
        article: article,
        updateArticle: updateArticle,
        deleteArticle: deleteArticle
    }
}