import {FILE_REMOVED, useFileDispatch, useFileState} from "./fileContext";
import {
    Box,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    ListSubheader,
    Paper,
    Snackbar,
    Stack,
    Typography
} from "@mui/material";
import {RemoveCircleOutline, UploadFile} from "@mui/icons-material";

export const UploadFeedback = () => {
    const {uploading} = useFileState()
    const fileDispatch = useFileDispatch()

    const handleRemove = file => {
        fileDispatch({
            type: FILE_REMOVED,
            file: file
        })
    }

    const secondaryText = file => {
        if(file.error) {
            return (
                <Typography
                    color={'error'}
                >
                    {file.error}
                </Typography>
            )
        } else {
            return (
                <Stack
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                    sx={{
                        minWidth: 200
                    }}
                >
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <LinearProgress variant="determinate" value={file.progress} />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography variant="body2" color="text.secondary">
                            {`${Math.round(file.progress)}%`}
                        </Typography>
                    </Box>
                </Stack>
            )
        }
    }

    return (
        <Snackbar
            open={uploading.length > 0}
            onClose={() => {}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
        >
            <Paper
                elevation={12}
            >
                <List
                    dense={true}
                    subheader={<ListSubheader>Laster opp filer</ListSubheader>}
                >
                    {uploading.map(file => (
                        <ListItem
                            key={file.id}
                        >
                            <ListItemIcon>
                                <UploadFile />
                            </ListItemIcon>
                            <ListItemText
                                primary={file.filename}
                                secondary={secondaryText(file)}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    disabled={!file.error}
                                    onClick={() => handleRemove(file)}
                                >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))}

                </List>
            </Paper>
        </Snackbar>
    )
}