import React from "react";
import {useFirebaseState} from "../firebase/firebaseContext";
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {EmailPasswordRepeatForm} from "./EmailPasswordRepeatForm";

export const SignupUsernamePassword = () => {
    const {auth} = useFirebaseState()


    const handleSignup = formData => {
        createUserWithEmailAndPassword(auth, formData.username, formData.newPassword)
            .then(userCredential => {
                console.debug("Signup successful")
                console.debug({user: userCredential.user})
                sendEmailVerification(userCredential.user)
                    .then(() => {
                        console.debug("Email verification sent")
                    })
                    .catch(error => {
                        console.error("Email verification failed")
                        console.error(error)
                    })
            })
            .catch(error => {
                console.debug({error})
            })
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email('Må være en gyldig epostadresse')
            .required('Skriv inn e-posten din'),
        newPassword: Yup.string()
            .required('Skriv inn passordet ditt'),
        repeatPassword: Yup.string()
            .required('Gjenta nøyaktig det samme passordet')
            .oneOf([Yup.ref('newPassword'), null],
                'Passordene er ikke like'
            )
    })

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={handleSignup}
        >
            <EmailPasswordRepeatForm />
        </FormProvider>

    )
}