import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import InfoIcon from '@mui/icons-material/Info';
import ListItemText from '@mui/material/ListItemText';
import {Divider, Hidden, ListItemButton, ListSubheader, SwipeableDrawer, styled} from "@mui/material";
import {useLocation} from 'react-router-dom';
import {MENU_CLOSED, MENU_OPENED, useHeaderDispatch, useHeaderState} from "../header/headerContext";
import {Icon} from "@iconify/react";
import stethoscopeIcon from '@iconify/icons-mdi/stethoscope';
import meditationIcon from '@iconify/icons-mdi/meditation';
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import ExploreIcon from '@mui/icons-material/Explore';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import {AccountCircle, ContactPage, Email, Folder, People} from "@mui/icons-material";
import {useNavigate} from "react-router";
import {useIsAuthenticated} from "../firebase/useIsAuthenticated";
import {useIsInstructorOrAdmin} from "../firebase/useIsInstructorOrAdmin";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerContainer: {
        overflow: 'auto',
    },
}));

const Nav = styled("nav")(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
    },
}))

const DrawerLogo = styled("img")(({theme}) => ({
    paddingTop: 2,
    paddingLeft: 2,
    paddingRight: 2
}))

export const Menu = () => {
    const classes = useStyles();
    const {menuOpen} = useHeaderState();
    const dispatch = useHeaderDispatch();
    const navigate = useNavigate()
    const isAdmin = useCheckIfAdmin();
    const location = useLocation();
    const authenticated = useIsAuthenticated()
    const instructorOrAdmin = useIsInstructorOrAdmin()


    const handleClick = url => {
        dispatch({type: MENU_CLOSED});
        navigate(url);
    }
    const adminMenu = () => {
        if(isAdmin) {
            return (
                <>
                    <Divider />
                    <List
                        subheader={<ListSubheader>Admin</ListSubheader>}
                    >
                        <ListItemButton
                            onClick={() => handleClick('/admin/users')}
                            selected={"/admin/users" === location.pathname}
                        >
                            <ListItemIcon>
                                <People />
                            </ListItemIcon>
                            <ListItemText primary={'Brukere'}/>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => handleClick('/administrate-instructors')}
                            selected={"/administrate-instructors" === location.pathname}
                        >
                            <ListItemIcon>
                                <SupervisorAccountIcon />
                            </ListItemIcon>
                            <ListItemText primary={'Kursholdere'}/>
                        </ListItemButton>
                        <ListItemButton
                            onClick={() => handleClick('/send-email-to-instructors')}
                            selected={"/send-email-to-instructors" === location.pathname}
                        >
                            <ListItemIcon>
                                <Email />
                            </ListItemIcon>
                            <ListItemText primary={'Epost'}/>
                        </ListItemButton>
                    </List>
                </>
            )
        } else {
            return null;
        }
    }

    const profileMenuItem = () => {
        if(authenticated) {
            return (
                <ListItemButton
                    onClick={() => handleClick('/me')}
                    selected={"/me" === location.pathname}
                >
                    <ListItemIcon>
                        <AccountCircle />
                    </ListItemIcon>
                    <ListItemText primary={'Profil'}/>
                </ListItemButton>
            )
        } else {
            return null
        }
    }

    const filesMenu = () => {
        if(instructorOrAdmin) {
            return (
                <>
                    <ListItemButton
                        onClick={() => handleClick('/instructor-profile')}
                        selected={"/instructor-profile" === location.pathname}
                    >
                        <ListItemIcon>
                            <ContactPage />
                        </ListItemIcon>
                        <ListItemText primary={'Instruktørprofil'}/>
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleClick('/files')}
                        selected={"/files" === location.pathname}
                    >
                        <ListItemIcon>
                            <Folder />
                        </ListItemIcon>
                        <ListItemText primary={'Filer'}/>
                    </ListItemButton>
                </>
            )
        } else {
            return null
        }
    }

    const menuList = (
        <>
            <List>
                <ListItemButton
                    onClick={() => handleClick('/about')}
                    selected={"/about" === location.pathname}
                >
                    <ListItemIcon>
                        <InfoIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Info om kurset'}/>
                </ListItemButton>
                {profileMenuItem()}
                {filesMenu()}
            </List>
            <Divider />
            <List
                subheader={<ListSubheader>For Privatpersoner</ListSubheader>}
            >
                <ListItemButton
                    selected={"/find-instructor" === location.pathname}
                    onClick={() => handleClick('/find-instructor')}
                >
                    <ListItemIcon>
                        <ExploreIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Finn kursholder'}/>
                </ListItemButton>
                <ListItemButton
                    selected={"/meditation" === location.pathname}
                    onClick={() => handleClick('/meditation')}
                >
                    <ListItemIcon>
                        <Icon icon={meditationIcon} width={"24"} height={"24"}/>
                    </ListItemIcon>
                    <ListItemText primary={'Meditasjonsøvelser'}/>
                </ListItemButton>
            </List>
            <Divider />
            <List
                subheader={<ListSubheader>For helsepersonell</ListSubheader>}
            >
                <ListItemButton
                    onClick={() => handleClick('/get-educated')}
                    selected={"/get-educated" === location.pathname}
                >
                    <ListItemIcon>
                        <Icon icon={stethoscopeIcon} width={"24"} height={"24"}/>
                    </ListItemIcon>
                    <ListItemText primary={'Bli kursholder'}/>
                </ListItemButton>
                <ListItemButton
                    onClick={() => handleClick('/order-books')}
                    selected={"/order-books" === location.pathname}
                >
                    <ListItemIcon>
                        <LocalLibraryIcon />
                    </ListItemIcon>
                    <ListItemText primary={'Bestill bøker'}/>
                </ListItemButton>
            </List>
        </>
    )

    return (
        <Nav
            aria-label={"dams menu drawer"}
        >
            <Hidden mdUp implementation="css">
                <SwipeableDrawer
                    anchor={'left'}
                    open={menuOpen}
                    onClose={() => dispatch({type: MENU_CLOSED})}
                    onOpen={() => dispatch({type: MENU_OPENED})}
                    sx={{
                        zIndex: theme => theme.zIndex.appBar - 1,
                        width: drawerWidth,
                        border: 0
                    }}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    <div className={classes.drawerContainer}>
                        <DrawerLogo
                            src={"/only_logo.png"}
                            width={"224px"}
                            onClick={() => handleClick("/")}
                        />
                        {menuList}
                        {adminMenu()}
                    </div>
                </SwipeableDrawer>
            </Hidden>
            <Hidden mdDown implementation="css">
                <Drawer
                    className={classes.drawer}
                    variant="permanent"
                    sx={{
                        zIndex: theme => theme.zIndex.appBar - 1,
                        width: drawerWidth,
                        border: 0
                    }}
                >
                    <Toolbar />
                    <DrawerLogo
                        src={"/only_logo.png"}
                        width={"224px"}
                        onClick={() => handleClick("/")}
                    />
                    <div className={classes.drawerContainer}>
                        {menuList}
                        {adminMenu()}
                    </div>
                </Drawer>
            </Hidden>
        </Nav>
    );
}
