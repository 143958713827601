import {TableCell, TableRow, Typography} from "@mui/material";
import {Article, AudioFile, Folder, FolderOpen, Image, PictureAsPdf, VideoFile} from "@mui/icons-material";
import React, {useCallback, useEffect} from "react";
import {formatDistance} from "date-fns";
import svLocale from "date-fns/locale/sv";
import nbLocale from "date-fns/locale/nb";
import nnLocale from "date-fns/locale/nn";
import enLocale from "date-fns/locale/en-US";
import {useDropzone} from "react-dropzone";
import {DRAG_TARGET_DESELECTED, DRAG_TARGET_SELECTED, useFolderDispatch} from "./folderContext";
import filesize from "filesize";
import {useFirebaseFileUpload} from "../firebase/useFirebaseFileUpload";

export const getLocale = () => {
    if ('sv' === navigator.language) {
        return svLocale;
    } else if ('no' === navigator.language || 'nb' === navigator.language) {
        return nbLocale;
    } else if ('nn' === navigator.language) {
        return nnLocale;
    } else {
        return enLocale;
    }
}

const toDateInstance = date => {
    if(date instanceof Date) {
        return date;
    } else {
        return new Date(date);
    }
}

export const distanceAgo = date => formatDistance(
    toDateInstance(date),
    new Date(),
    {
        addSuffix: true, locale: getLocale()
    }
)

export const FolderTableRow = ({row, onClick, selected}) => {
    const folderDispatch = useFolderDispatch()
    const {uploadFile} = useFirebaseFileUpload({
        folder: row
    })

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                uploadFile(file)
            })
        },
        []
    )

    const {
        getRootProps,
        isDragActive,
    } = useDropzone({
        onDrop: onDrop,
        noClick: true,
        noDragEventsBubbling: true
    })

    useEffect(
        () => {
            if(isDragActive) {
                folderDispatch({
                    type: DRAG_TARGET_SELECTED,
                    dropTarget: row
                })
            } else {
                folderDispatch({
                    type: DRAG_TARGET_DESELECTED,
                    dropTarget: row
                })
            }
        },
        [isDragActive, folderDispatch, row]
    )

    const icon = () => {
        const mimeType = row.type.split('/')[0]
        switch(Array.isArray(mimeType) ? mimeType[0] : mimeType) {
            case 'folder':
                return <Folder
                    color={selected ? 'primary' : 'inherit'}
                />
            case 'image':
                return <Image
                    color={selected ? 'primary' : 'inherit'}
                />
            case 'audio':
                return <AudioFile
                    color={selected ? 'primary' : 'inherit'}
                />
            case 'video':
                return <VideoFile
                    color={selected ? 'primary' : 'inherit'}
                />
            case 'application':
                return mimeType[1] === 'pdf'
                    ? <PictureAsPdf
                        color={selected ? 'primary' : 'inherit'}
                    />
                    : <Article
                        color={selected ? 'primary' : 'inherit'}
                    />
            default:
                return <Article
                    color={selected ? 'primary' : 'inherit'}
                />
        }
    }

    return (
        <TableRow
            hover={true}
            selected={selected || isDragActive}
            sx={{
                cursor: 'pointer'
            }}
            {...getRootProps()}
            onClick={onClick}
        >
            <TableCell
                align={"right"}
            >
                {icon()}
            </TableCell>
            <TableCell>
                <Typography>
                    {row.title}
                </Typography>
            </TableCell>
            <TableCell>
                {distanceAgo(row.createdAt.toDate())}
            </TableCell>
            <TableCell>
                <Typography>
                    {row.fileSize ? filesize(row.fileSize) : "-"}
                </Typography>
            </TableCell>
        </TableRow>
    )
}