import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {collection, getDocs, orderBy, query} from 'firebase/firestore';

export const useNews = ({orderBy: byOrder, order, forceRefresh}) => {
    const [news, setNews] = useState([]);
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const newsRef = collection(db, 'news');
            const q = query(newsRef, orderBy(byOrder, order))
            getDocs(q)
                .then(querySnapshot => {
                    const tempNews = []
                    querySnapshot.forEach(doc => tempNews.push(({id: doc.id, ...doc.data()})))
                    setNews(tempNews)
                })

        }
    }, [db, setNews, orderBy, order, forceRefresh])

    return news
}