import React, {useRef, useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {BookOrderStepper} from "./BookOrderStepper";
import {PersonOrderingStep} from "./PersonOrderingStep";
import {StepContent} from "./StepContent";
import {NumberOfBooksStep} from "./NumberOfBooksStep";
import {RecipientStep} from "./RecipientStep";
import {InvoiceStep} from "./InvoiceStep";
import {FormActions} from "./FormActions";
import {useEmail} from "../firebase/useEmail";
import ReCAPTCHA from "react-google-recaptcha";
import {styled} from "@mui/material";


const Form = styled("div")(({theme}) => ({
    display: "flex",
    flexDirection: "column",
    marginTop: 2
}))

const schema = (
    Yup.object().shape({
        name: Yup.string()
            .required("Påkrevd"),
        email: Yup.string()
            .email("Må være en gyldig epostadresse")
            .required("Påkrevd"),
        phone: Yup.string()
            .required("Påkrevd"),
        addressName: Yup.string()
            .required("Påkrevd"),
        addressStreet: Yup.string()
            .required("Påkrevd"),
        addressAreaCode: Yup.number()
            .required("Påkrevd"),
        addressPlace: Yup.string()
            .required("Påkrevd"),
        contactPerson: Yup.string()
            .required("Påkrevd"),
        contactPhone: Yup.string()
            .required("Påkrevd"),
        invoiceName: Yup.string()
            .required("Påkrevd"),
        invoiceOrgNumber: Yup.string(),
        invoiceReference: Yup.string(),
        invoiceAddress: Yup.string()
            .required("Påkrevd"),
        invoiceAreaCode: Yup.number()
            .required("Påkrevd"),
        invoicePlace: Yup.string()
            .required("Påkrevd"),
    })
);

export const BookOrderForm = ({onClose}) => {
    const [activeStep, setActiveStep] = useState(0);
    const [send] = useEmail();
    const recaptchaRef = useRef();

    const valuesToText = values => {
        return `
        Bestiller:
        Navn: ${values.name}
        Epostadresse: ${values.email}
        Telefon: ${values.phone}
        
        Antall bøker: ${values.numberOfBooks}
        
        Levering:
        Navn: ${values.addressName}
        Adresse: ${values.addressStreet}
        Postnummer: ${values.addressAreaCode}
        Poststed: ${values.addressPlace}
        Leveringskontakt:
        Navn: ${values.contactPerson}
        Telefon: ${values.contactPhone}
        
        Betaling:
        Navn: ${values.invoiceName}
        Organisasjonsnummer: ${values.invoiceOrgNumber}
        Referanse: ${values.invoiceReference}
        Adresse: ${values.invoiceAddress}
        Postnummer: ${values.invoiceAreaCode}
        Poststed: ${values.invoicePlace}`
    }

    const valuesToHtml = values => {
        return `
        Bestiller:<br />
        Navn: ${values.name}<br />
        Epostadresse: ${values.email}<br />
        Telefon: ${values.phone}<br />
        <br />
        Antall bøker: ${values.numberOfBooks}<br />
        <br />
        Levering:<br />
        Navn: ${values.addressName}<br />
        Adresse: ${values.addressStreet}<br />
        Postnummer: ${values.addressAreaCode}<br />
        Poststed: ${values.addressPlace}<br />
        Leveringskontakt:<br />
        Navn: ${values.contactPerson}<br />
        Telefon: ${values.contactPhone}<br />
        <br />
        Betaling:<br />
        Navn: ${values.invoiceName}<br />
        Organisasjonsnummer: ${values.invoiceOrgNumber}<br />
        Referanse: ${values.invoiceReference}<br />
        Adresse: ${values.invoiceAddress}<br />
        Postnummer: ${values.invoiceAreaCode}<br />
        Poststed: ${values.invoicePlace}<br />`
    }

    const onSubmitWithReCAPTCHA = async () => {
        await recaptchaRef.current.executeAsync();
    }

    const onSubmit = values => {
        onSubmitWithReCAPTCHA()
            .then(() => {
                send({
                    to: ["post@bekymringsmestring.no"],
                    cc: [values.email, 'serina_vorland@hotmail.com'],
                    subject: "Bestilling av arbeidsbøker til Kurs i Bekymringsmestring",
                    text: valuesToText(values),
                    html: valuesToHtml(values)
                })
                onClose();
            })
    }

    const getStepContent = () => {
        switch(activeStep) {
            case 0:
                return <PersonOrderingStep />
            case 1:
                return <NumberOfBooksStep />
            case 2:
                return <RecipientStep />
            case 3:
                return <InvoiceStep />
            default:
                throw new Error(`not implemented step ${activeStep}`)
        }
    }

    return (
        <>
            <Formik
                initialValues={{
                    name: "",
                    email: "",
                    phone: "",
                    numberOfBooks: 1,
                    addressName: "",
                    addressStreet: "",
                    addressAreaCode: "",
                    addressPlace: "",
                    contactPerson: "",
                    contactPhone: "",
                    invoiceName: "",
                    invoiceOrgNumber: "",
                    invoiceReference: "",
                    invoiceAddress: "",
                    invoiceAreaCode: "",
                    invoicePlace: ""

                }}
                onSubmit={values => onSubmit(values)}
                validationSchema={schema}
            >
                <Form>
                    <BookOrderStepper
                        activeStep={activeStep}
                        changeStep={setActiveStep}
                    >
                        <StepContent>
                            {getStepContent()}
                        </StepContent>
                    </BookOrderStepper>
                    <FormActions />
                </Form>
            </Formik>
            <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdzIyIaAAAAAMimuhzMSC6p3McrnNIJha7pKkqe"
            />
        </>
    );
}