import {collection, getDocs, query} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {USERS_CLEARED, USERS_RECEIVED, useUserDispatch, useUserState} from "./userContext";
import {useEffect} from "react";

export const useUsers = ({forceRefresh}) => {
    const {db} = useFirebaseState();
    const {users} = useUserState();
    const userDispatch = useUserDispatch()

    useEffect(
        () => {
            userDispatch({
                type: USERS_CLEARED
            })
        }, [
            forceRefresh,
            userDispatch,
        ]
    )


    useDeepCompareEffect(() => {
        const buildQuery = () => {
            const usersRef = collection(db, 'users')
            return Promise.resolve(
                query(
                    usersRef,
                )
            )
        }

        if(db) {
            buildQuery()
                .then(query => {
                    getDocs(query)
                        .then(querySnapshot => {
                            const tempDocs = []
                            querySnapshot.forEach(doc => {
                                tempDocs.push({...doc.data(), id: doc.id})
                            })
                            userDispatch({
                                type: USERS_RECEIVED,
                                users: tempDocs.filter(u => !u.deleted)
                            })
                        })
                })

        }
    }, [db, userDispatch, forceRefresh])

    return users;
}