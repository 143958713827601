import {EDIT_USER_CLOSED, useUserDispatch, useUserState} from "./userContext";
import {
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import React, {useEffect} from "react";
import {ConnectInstructor} from "./ConnectInstructor";
import {useGrantInstructor} from "./useGrantInstructor";
import {useMyInstructorProfile} from "../profile/useMyInstructorProfile";
import {useDeleteUser} from "./useDeleteUser";

export const EditUser = () => {
    const {
        editUser
    } = useUserState()

    const userDispatch = useUserDispatch()
    const deleteUser = useDeleteUser()
    const [connectInstructor, setConnectInstructor] = React.useState(false)
    const {
        grantInstructor,
        grantInstructorRole,
        refresh
    } = useGrantInstructor()

    const {
        instructor,
    } = useMyInstructorProfile({
        userId: editUser?.owner,
    })

    const closeHandler = () => {
        setConnectInstructor(false)
        userDispatch({type: EDIT_USER_CLOSED})
    }

    const grantHandler = () => {
        grantInstructor({user: editUser})
    }

    const grantRoleHandler = () => {
        grantInstructorRole({user: editUser})
    }

    const divider = () => {
        if(connectInstructor) {
            return <Divider orientation="vertical" flexItem />
        } else {
            return null
        }
    }

    useEffect(
        () => {
            if(refresh > 0) {
                closeHandler()
            }
        }, [
            refresh
        ]
    )


    const connectButtons = () => {
        console.debug({instructor})
        if(instructor?.id && editUser?.role === 'User') {
            return (
                <Button
                    disabled={editUser.role !== 'User'}
                    onClick={grantRoleHandler}
                    variant={"outlined"}
                >
                    Gi instruktør rolle
                </Button>
            )
        } else if(Object.keys(instructor).length > 0 && !instructor?.id){
            return (
                <>
                    <Button
                        variant={"outlined"}
                        disabled={!editUser || connectInstructor}
                        onClick={() => setConnectInstructor(true)}
                    >
                        Koble til eksisterende instruktør
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={!editUser || editUser.role !== 'User' || connectInstructor}
                        onClick={grantHandler}
                    >
                        Gjør til ny instruktør
                    </Button>
                </>
            )
        } else {
            return null
        }
    }

    const deleteHandler = () => {
        deleteUser({user: editUser}).then(closeHandler)

    }

    return (
        <Dialog
            open={Boolean(editUser)}
            onClose={closeHandler}
            fullWidth={true}
            maxWidth={'xl'}
        >
            <DialogTitle>
                Rediger {editUser?.name || editUser?.email}
            </DialogTitle>
            <DialogContent
                sx={{
                    display: "flex",
                    width: "100%",
                    padding: 0,
                }}
            >
                    <Stack
                        direction={"row"}
                        sx={{
                            width: "100%",
                            margin: 2
                        }}
                        divider={divider()}
                    >
                        <Grid
                            container={true}
                            spacing={2}
                        >
                            <Grid
                                item={true}
                                xs={3}
                            >
                                <Avatar
                                    src={editUser?.photoURL}
                                    alt={editUser?.displayName}
                                    sx={{
                                        width: 100,
                                        height: 100
                                    }}
                                />
                            </Grid>
                            <Grid
                                item={true}
                                xs={9}
                            >
                                <Stack
                                    spacing={1}
                                    alignItems={"flex-start"}
                                >

                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                    >
                                        <Typography>
                                            Epost: {editUser?.email}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        direction={"row"}
                                        alignItems={"center"}
                                    >
                                        <Typography>
                                            Navn: {editUser?.name}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                        <ConnectInstructor
                            open={connectInstructor}
                            user={editUser}
                            onClose={closeHandler}
                        />
                    </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={closeHandler}
                >
                    Lukk vindu
                </Button>
                <Button
                    variant={"contained"}
                    color={"error"}
                    onClick={deleteHandler}
                >
                    Slett bruker
                </Button>
                {connectButtons()}
            </DialogActions>
        </Dialog>
    )
}