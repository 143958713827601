import {Button} from "@mui/material";
import {ArrowDropDown, ArrowRight} from "@mui/icons-material";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";

export const BreadCrumbButton = ({isLast, title, onClick}) => {
    const isAdmin = useCheckIfAdmin()
    return (
        <Button
            disabled={isLast && !isAdmin}
            endIcon={isLast ? <ArrowDropDown /> : <ArrowRight />}
            onClick={onClick}
        >
            {title}
        </Button>
    )
}