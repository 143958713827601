import {useFirebaseState} from "./firebaseContext";
import {collection, doc, setDoc} from 'firebase/firestore'

export const useEmail = () => {
    const {db} = useFirebaseState();

    const send = ({to, cc, subject, text, html}) => {
        const emailRef = collection(db, 'email')
        setDoc(
            doc(emailRef),
            {
                to: to,
                cc: cc,
                message: {
                    subject: subject,
                    text: text,
                    html: html
                }
            }
        )
            .then(() => {
                console.debug('email sent')
            })
            .catch(err => {
                console.error(err)
            })
    }

    return [send]
}