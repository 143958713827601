import React from 'react';

export const USER_LOGGED_IN = 'firebaseContext/loggedIn';
export const USER_LOGGED_OUT = 'firebaseContext/loggedOut';
export const INITIALIZED = 'firebaseContext/initialized';

const FirebaseStateContext = React.createContext(undefined);
const FirebaseDispatchContext = React.createContext(undefined);

const initialState = {
    displayName: null,
    db: null,
    uid: null,
    auth: null,
    admin: false,
    instructor: false
};

const firebaseReducer = (state, action) => {
    console.debug(action);
    switch(action.type) {
        case INITIALIZED: {
            return {
                ...state,
                db: action.db,
                auth: action.auth
            }
        }
        case USER_LOGGED_IN:
            return {
                ...state,
                displayName: action.displayName,
                uid: action.uid,
                admin: action.admin,
                instructor: action.instructor
            }
        case USER_LOGGED_OUT:
            return {
                ...state,
                displayName: null,
                uid: null,
                admin: false,
                instructor: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const FirebaseProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(firebaseReducer, {...initialState}, undefined);

    return (
        <FirebaseStateContext.Provider value={state}>
            <FirebaseDispatchContext.Provider value={dispatch}>
                {children}
            </FirebaseDispatchContext.Provider>
        </FirebaseStateContext.Provider>
    )
}

export const useFirebaseState = () => {
    const context = React.useContext(FirebaseStateContext);
    if(undefined === context) {
        throw new Error('useFirebaseState must be used witin a FirebaseProvider')
    }
    return context;
}

export const useFirebaseDispatch = () => {
    const context = React.useContext(FirebaseDispatchContext);
    if(undefined === context) {
        throw new Error('useFirebaseDispatch must be used witin a FirebaseProvider')
    } else {
        return context;
    }
}