import React from "react";
import {useFirebaseState} from "../firebase/firebaseContext";
import {sendPasswordResetEmail} from "firebase/auth";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {ForgotPasswordForm} from "./ForgotPasswordForm";

export const ForgotPassword = ({onClose}) => {
    const {auth} = useFirebaseState()


    const handleSignup = formData => {
        sendPasswordResetEmail(
            auth, formData.username
        ).then(r => onClose())
            .catch(error => onClose())
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email('Må være en gyldig epostadresse')
            .required('Skriv inn e-posten din'),
    })

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={handleSignup}
        >
            <ForgotPasswordForm />
        </FormProvider>

    )
}