import {createContext, useContext, useReducer} from "react";

export const FILE_ADDED = 'FILE_ADDED';
export const FILE_PROGRESS_UPDATED = 'FILE_PROGRESS_UPDATED';
export const FILE_REMOVED = 'FILE_REMOVED';
export const FILE_ERROR = 'FILE_ERROR';

const FileStateContext = createContext(undefined)
const FileDispatchContext = createContext(undefined)

const initialState = {
    uploading: [],
}

const fileReducer = (state, action) => {
    switch (action.type) {
        case FILE_ADDED: {
            return {
                ...state,
                uploading: [
                    ...state.uploading,
                    action.file,
                ],
            }
        }
        case FILE_PROGRESS_UPDATED: {
            return {
                ...state,
                uploading: state.uploading.map(file => {
                    if (file.id === action.file.id) {
                        return action.file
                    }
                    return file
                }),
            }
        }
        case FILE_REMOVED: {
            return {
                ...state,
                uploading: state.uploading.filter(file => file.id !== action.file.id),
            }
        }
        case FILE_ERROR: {
            return {
                ...state,
                uploading: state.uploading.map(file => {
                    if (file.id === action.file.id) {
                        return {
                            ...file,
                            error: action.error,
                        }
                    }
                    return file
                }),
            }
        }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const FileProvider = ({ children }) => {
    const [state, dispatch] = useReducer(fileReducer, initialState, undefined)
    return (
        <FileStateContext.Provider value={state}>
            <FileDispatchContext.Provider value={dispatch}>
                {children}
            </FileDispatchContext.Provider>
        </FileStateContext.Provider>
    )
}

export const useFileState = () => {
    const context = useContext(FileStateContext)
    if (context === undefined) {
        throw new Error('useFileState must be used within a FileProvider')
    } else {
        return context
    }
}

export const useFileDispatch = () => {
    const context = useContext(FileDispatchContext)
    if (context === undefined) {
        throw new Error('useFileDispatch must be used within a FileProvider')
    } else {
        return context
    }
}