import {FormikTextField} from "../form/FormikTextField";
import React from "react";


export const PersonOrderingStep = () => {

    return (
        <>
            <FormikTextField
                formikKey={"name"}
                label={"Navn"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"email"}
                label={"Epostadresse"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"phone"}
                label={"Telefonnummer"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
        </>
    )
}