import {FormControl, InputLabel, Select} from "@mui/material";
import React from "react";
import {FORM_VALUE_UPDATED, useFormDispatch, useFormState} from "./formContext";
import {useFieldValidation} from "./useFieldValidation";
import {useIsFieldRequired} from "./useIsFieldRequired";


export const SelectForm = ({formKey, label, disabled=false, children}) => {
    const {
        formData,
        errors
    } = useFormState()

    const dispatch = useFormDispatch()
    useFieldValidation(formKey)
    const required = useIsFieldRequired(formKey)

    const changeHandler = event => dispatch({
        type: FORM_VALUE_UPDATED,
        key: formKey,
        value: event.target.value
    })

    return (
        <FormControl
            required={required}
            disabled={disabled}
        >
            <InputLabel id={`${formKey}-label`}>
                {label}
            </InputLabel>
            <Select
                id={formKey}
                labelId={`${formKey}-label`}
                name={label}
                label={label}
                error={Boolean(errors[formKey])}
                value={formData[formKey]}
                onChange={changeHandler}

            >
                {children}
            </Select>
        </FormControl>
    )
}