import React, {useEffect, useState} from 'react';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {AdminMap} from "./AdminMap";
import TextField from "@mui/material/TextField";
import makeStyles from '@mui/styles/makeStyles';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

const useStyles = makeStyles(theme => ({
    searchInput: {
        display: "flex",
        justifyContent: "center"
    },
    mapContainer: {
        height: 400
    }
}))

export const EditInstructorMap = ({initialInput, onMarkerSet, existingLocation}) => {
    const [searchInput, setSearchInput] = useState();
    const [position, setPosition] = useState([]);
    const [zoom, setZoom] = useState(4);
    const classes = useStyles();

    const handleSearch = (query, initial=false) => {
        if(!query) {
            return
        }
        const params = {
            q: query,
            limit: 1,
            format: "json"
        }
        const url = 'https://nominatim.openstreetmap.org/search?'
        const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
        fetch(`${url}${queryParams}`)
            .then(response => response.json())
            .then(json => {
                if(json.length > 0) {
                    setPosition([json[0].lat, json[0].lon])
                    setZoom(12)
                    onMarkerSet([json[0].lat, json[0].lon])
                }
            })
    }

    useEffect(() => {
        setSearchInput(initialInput)
        if(initialInput && existingLocation.length < 2) {
            handleSearch(initialInput, true)
        } else if(existingLocation.length > 1) {
            setPosition(existingLocation)
        }
    }, [initialInput, JSON.stringify(existingLocation)])

    const getMarker = () => {
        if(existingLocation.length > 1) {
            return (
                <Marker position={existingLocation}/>
            )
        }
        return null
    }

    return (
        <>
            <Toolbar>
                <TextField
                    id="search"
                    label="Søk i kart"
                    variant="filled"
                    sx={{
                        mb: 2,
                        mr: 2
                    }}
                    value={searchInput}
                    fullWidth={true}
                    onChange={event => setSearchInput(event.target.value)}
                    onKeyPress={ev => {
                        if("Enter" === ev.key) {
                            handleSearch(searchInput)
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button variant={"contained"} color={"secondary"} onClick={() => handleSearch(searchInput)} type={"submit"}>
                    Søk
                </Button>
            </Toolbar>
            <MapContainer center={[65, 11]} zoom={zoom} className={classes.mapContainer}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AdminMap position={position} clickHandler={({lat, lng}) => onMarkerSet([lat, lng])}/>
                {getMarker()}
            </MapContainer>
        </>
    )
}