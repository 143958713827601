import {IconButton, InputAdornment, Stack} from "@mui/material";
import {Form} from "../form/Form";
import React, {useEffect, useState} from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {FormSubmit} from "../form/FormSubmit";
import {FORM_INITIALIZED, useFormDispatch, useFormState} from "../form/formContext";

export const EmailPasswordRepeatForm = ({disabled=false}) => {
    const [showPassword, setShowPassword] = useState(false)
    const [showRepeatPassword, setShowRepeatPassword] = useState(false)
    const formDispatch = useFormDispatch()
    const {initialized} = useFormState()

    useEffect(
        () => {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    username: '',
                    newPassword: '',
                    repeatPassword: ''
                }
            })
        },[formDispatch]
    )

    if(!initialized) {
        return null
    }

    return (
        <Form>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{
                    mt: 2
                }}
            >
                <TextFieldForm
                    formKey={'username'}
                    label={'E-post'}
                    ariaLabel={'Skriv inn e-posten din'}
                    fullWidth={true}
                    autoFocus={true}
                    disabled={Boolean(disabled)}
                />

                <TextFieldForm
                    formKey={'newPassword'}
                    label={'Passord'}
                    ariaLabel={'Skriv inn passordet ditt'}
                    fullWidth={true}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={e => e.preventDefault()}
                                    tabIndex={-1}
                                    size="large">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled={Boolean(disabled)}
                />
                <TextFieldForm
                    formKey={'repeatPassword'}
                    label={'Gjenta nytt passord'}
                    ariaLabel={'Gjenta nøyaktig det samme passordet'}
                    fullWidth={true}
                    type={showRepeatPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                    onMouseDown={e => e.preventDefault()}
                                    tabIndex={-1}
                                    size="large">
                                    {showRepeatPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled={disabled}
                />
                <FormSubmit
                    disabled={disabled}
                    variant={"contained"}
                >
                    Opprett
                </FormSubmit>
            </Stack>
        </Form>
    )
}