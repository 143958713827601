import {createContext, useContext, useReducer} from "react";

const FormStateContext  = createContext(undefined)
const FormDispatchContext  = createContext(undefined)

export const FORM_INITIALIZED = 'form/init'
export const FORM_VALUE_UPDATED = 'form/valueUpdated'
export const FORM_SUBMITTED = 'form/submitted'
export const FORM_SAVED = 'form/saved'
export const FORM_VALUE_ERROR = 'form/valueError'
export const FORM_VALUE_NO_ERROR = 'form/valueNoError'

const initialState = {
    initialFormData: {},
    formData: {},
    initialized: false,
    isSubmitting: false,
    errors: {},
    modified: false,
}

const formReducer = (state, action) => {
    console.debug(action)
    switch(action.type) {
        case FORM_INITIALIZED:
            return {
                ...state,
                formData: action.initialFormData,
                initialFormData: action.initialFormData,
                initialized: true,
            }
        case FORM_VALUE_UPDATED:
            return {
                ...state,
                modified: true,
                isSubmitting: false,
                formData: {
                    ...state.formData,
                    [action.key]: action.value
                },
            }
        case FORM_SUBMITTED: {
            state.onSubmit(state.formData)
            return {
                ...state,
                isSubmitting: true,
            }
        }

        case FORM_SAVED: {
            return {
                ...state,
                isSubmitting: false,
                modified: false,
            }
        }

        case FORM_VALUE_ERROR: {
            return {
                ...state,
                errors: {
                    ...state.errors,
                    [action.key]: action.exception.errors[0]
                }
            }
        }
        case FORM_VALUE_NO_ERROR: {
            const {[action.key]: remove, ...updatedErrors} = state.errors
            return {
                ...state,
                errors: updatedErrors
            }
        }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
}

export const FormProvider = ({onSubmit, schema=null, children}) => {
    const [state, dispatch] = useReducer(
        formReducer,
        {
            ...initialState,
            onSubmit: onSubmit,
            schema: schema
        },
        undefined
    )

    return (
        <FormStateContext.Provider value={state}>
            <FormDispatchContext.Provider value={dispatch}>
                    {children}
            </FormDispatchContext.Provider>
        </FormStateContext.Provider>
    )

}

export const useFormState = () => {
    const context = useContext(FormStateContext)

    if(undefined === context) {
        throw new Error("useFormState must be used within a FormProvider");
    } else {
        return context
    }
}

export const useFormDispatch = () => {
    const context = useContext(FormDispatchContext)

    if(undefined === context) {
        throw new Error("useFormDispatch must be used within a FormProvider");
    } else {
        return context
    }
}
