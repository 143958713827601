import React from 'react';
import {usePublishedInstructors} from "../firebase/usePublishedInstructors";
import {Marker, Popup, useMap} from "react-leaflet";
import {Typography} from "@mui/material";
import MarkerClusterGroup from 'react-leaflet-markercluster';

export const InstructorMarkers = () => {
    const publishedInstructors = usePublishedInstructors({});
    const map = useMap();


    return (
        <MarkerClusterGroup>
            {publishedInstructors.map(instructor => (
                <Marker key={instructor.id} position={{lat: instructor.lat, lng: instructor.lon}}>
                    <Popup>
                        <Typography>{instructor.name}</Typography>
                        <Typography>{instructor.title}</Typography>
                        <Typography>{instructor.description}</Typography>
                        <Typography>
                            Send epost: <a href={`mailto:${instructor.email}`}>{instructor.email}</a>
                        </Typography>
                        <Typography>
                            Ring: <a href={`tel:${instructor.phone}`}>{instructor.phone}</a>
                        </Typography>
                    </Popup>
                </Marker>
            ))}
        </MarkerClusterGroup>
    )
}