import {Stack} from "@mui/material";
import {Form} from "../form/Form";
import React, {useEffect} from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {FORM_INITIALIZED, useFormDispatch, useFormState} from "../form/formContext";

export const BasicProfileForm = ({user, disabled=false}) => {
    const formDispatch = useFormDispatch()
    const {initialized} = useFormState()

    useEffect(
        () => {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    displayName: user.displayName || "",
                }
            })
        },[]
    )


    if(!initialized) {
        return null
    }


    return (
        <Form>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{
                    mt: 2
                }}
            >
                <TextFieldForm
                    formKey={'displayName'}
                    label={'Navn'}
                    ariaLabel={'Skriv inn navnet ditt'}
                    fullWidth={true}
                    autoFocus={true}
                    disabled={Boolean(disabled)}
                />
            </Stack>
        </Form>
    )
}