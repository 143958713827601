import {useEffect, useState} from "react";
import {useFirebaseState} from "./firebaseContext";
import {doc, getDoc, updateDoc} from 'firebase/firestore'

export const useAboutContent = ({forceRefresh=0}) => {
    const [aboutContent, setAboutContent] = useState('');
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const aboutContentRef = doc(db, 'content', 'about');
            getDoc(aboutContentRef)
                .then(doc => {
                    if(doc.exists) {
                        setAboutContent(doc.data().content)
                    }
                })

        }
    }, [db, setAboutContent, forceRefresh])

    const updateAboutContent = content => {
        if(db) {
            const aboutContentRef = doc(db, 'content', 'about');
            updateDoc(aboutContentRef, {content: content}).then(r => console.debug({r}))
        }
    }

    return [aboutContent, updateAboutContent];
}