import React from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {MenuItem, Stack, Typography} from "@mui/material";
import {SelectForm} from "../form/SelectForm";
import {useFormState} from "../form/formContext";

const counties = [
    "Agder",
    "Innlandet",
    "Møre og Romsdal",
    "Nordland",
    "Oslo",
    "Rogaland",
    "Vestfold og Telemark",
    "Troms og Finnmark",
    "Trøndelag",
    "Vestland",
    "Viken"
]

export const InstructorForm = ({disabled = false}) => {
    const {
        initialized,
        formData
    } = useFormState()

    if(!initialized) {
        return null
    }

    return (
        <Stack
            spacing={2}
        >
            <Typography
                variant={"h6"}
            >
                Informasjon som vil publiseres om deg
            </Typography>
            <TextFieldForm
                formKey={'name'}
                label="Navn"
                fullWidth={true}
                autoFocus={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'title'}
                label="Tittel"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'description'}
                label="Info"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'email'}
                label="Epost"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'phone'}
                label="Telefon"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'address'}
                label="Adresse"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <TextFieldForm
                formKey={'place'}
                label="Sted"
                fullWidth={true}
                disabled={Boolean(disabled)}
            />
            <SelectForm
                formKey={'county'}
                label={"Fylke"}
                disabled={Boolean(disabled)}
            >
                {counties.map(county => (
                    <MenuItem
                        key={county}
                        value={county}
                    >
                        {county}
                    </MenuItem>
                ))}
            </SelectForm>
        </Stack>
    )
}