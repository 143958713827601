import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {MapContainer, TileLayer} from "react-leaflet";
import {InstructorMarkers} from "./InstructorMarkers";

const useStyles = makeStyles(theme => ({
    leafletContainer: {
        width: "100%",
        height: "70vh"
    }
}));

export const InstructorsMap = () => {
    const classes = useStyles();
    return (
        <div className={classes.leafletContainer}>
            <MapContainer center={[65, 11]} zoom={4}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <InstructorMarkers />
            </MapContainer>
        </div>
    )
}