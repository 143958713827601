import {useInstructors} from "../firebase/useInstructors";
import {Box, Button} from "@mui/material";


export const EmailContent = () => {
    const instructors = useInstructors({
        orderBy: "name",
        order: "asc",
        forceRefresh: false
    });

    const handleSendEmail = bcc => {
        window.open(`mailto:?bcc=${bcc}`)
    }

    return (
        <Box
            sx={{
                mt: 2
            }}
        >
            <Button
                onClick={() => handleSendEmail(
                    instructors.filter(instructor => instructor.email)
                        .map(instructor => instructor.email)
                        .join(',')
                )}
                variant={"contained"}
                color={"primary"}
            >
                Send epost til kursholdere
            </Button>
        </Box>
    )
}