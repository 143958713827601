import React, {useState} from 'react';
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import {useInstructors} from "../firebase/useInstructors";
import Table from "@mui/material/Table";
import {Button, IconButton, TableBody, TableContainer, Toolbar, Typography, useMediaQuery} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {EditInstructor} from "./EditInstructor";
import {usePublishedInstructors} from "../firebase/usePublishedInstructors";
import CheckIcon from '@mui/icons-material/Check';
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";

const useStyles = makeStyles((theme) => ({
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    tableRow: {
        '&:hover': {
            cursor: 'pointer'
        }
    }
}));

export const InstructorsTable = () => {
    const [orderBy, setOrderBy] = useState("name");
    const [order, setOrder] = React.useState('asc');
    const [selectedInstructor, setSelectedInstructor] = useState(null);
    const [forceRefresh, setForceRefresh] = useState(0)
    const [addNew, setAddNew] = useState(false);
    const mdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

    const instructors = useInstructors({
        orderBy: orderBy,
        order: order,
        forceRefresh: forceRefresh
    });

    const publishedInstructors = usePublishedInstructors({});

    const classes = useStyles();

    const headCells = [
        {
            id: 'name',
            label: 'Navn',
            sx: {}
        },
        {
            id: 'place',
            label: 'Plass',
            sx: {
                display: {
                    xs: "none",
                    md: "table-cell"
                },
                width: {
                    xs: 0,
                    md: 200
                }
            }
        },
        {
            id: 'county',
            label: 'Fylke',
            sx: {
                display: {
                    xs: "none",
                    md: "table-cell"
                },
                width: {
                    xs: 0,
                    md: 200
                }
            }
        }
    ]

    const handleSortClick = columnName => {
        if(orderBy === columnName) {
            setOrder(o => "asc" === o ? "desc" : "asc")
        } else {
            setOrderBy(columnName);
            setOrder("asc");
        }
    }

    const isPublished = instructorId => {
        return publishedInstructors.map(publishedInstructor => publishedInstructor.id).includes(instructorId);
    }

    const getAddButton = () => {
        if(!mdUp) {
            return (
                <IconButton
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => setAddNew(true)}
                    size="large">
                    <AddIcon />
                </IconButton>
            );
        } else {
            return (
                <Button
                    variant={"contained"}
                    color={"primary"}
                    startIcon={<AddIcon />}
                    onClick={() => setAddNew(true)}
                >
                    Ny kursholder
                </Button>
            )
        }
    }

    return (
        <>
            <Toolbar>
                <Typography variant={"h4"} component={"h2"}>
                    Kursholdere
                </Typography>
                <Box flexGrow={1} />
                {getAddButton()}
            </Toolbar>
            <TableContainer>
                <Table
                    size={"small"}
                >
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell) => (
                                <TableCell
                                    key={headCell.id}
                                    align={"left"}
                                    sortDirection={orderBy === headCell.id ? order : false}
                                    sx={headCell.sx}
                                >
                                    <TableSortLabel
                                        active={false}
                                        direction={orderBy === headCell.id ? order : "asc"}
                                        onClick={() => handleSortClick(headCell.id)}
                                    >
                                        {headCell.label}
                                        {orderBy === headCell.id ? (
                                            <span className={classes.visuallyHidden}>
                                                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                            </span>
                                        ) : null}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                            <TableCell>
                                Publisert
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {instructors
                            .filter(instructor => !instructor.deleted)
                            .map(instructor => (
                            <TableRow key={instructor.id} onClick={() => setSelectedInstructor(instructor.id)} className={classes.tableRow}>
                                <TableCell>
                                    {instructor.name}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        display: {
                                            xs: "none",
                                            md: "table-cell"
                                        },
                                        width: {
                                            xs: 0,
                                            md: 200
                                        }
                                    }}
                                >
                                    {instructor.place}
                                </TableCell>
                                <TableCell
                                    sx={{
                                        display: {
                                            xs: "none",
                                            md: "table-cell"
                                        },
                                        width: {
                                            xs: 0,
                                            md: 200
                                        }
                                    }}
                                >
                                    {instructor.county}
                                </TableCell>
                                <TableCell>
                                    {isPublished(instructor.id) ? <CheckIcon color={"secondary"}/> : null}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <EditInstructor
                open={null !== selectedInstructor || addNew}
                handleClose={(refresh) => {
                    if(refresh) {
                        setForceRefresh(fr => forceRefresh + 1)
                    }
                    setAddNew(false);
                    setSelectedInstructor(null)
                }}
                instructorId={selectedInstructor}
            />
        </>
    )
}