import {Box, LinearProgress, TableCell, TableHead, TableRow, TableSortLabel} from "@mui/material";
import {visuallyHidden} from "@mui/utils";

const headCells = [
    {
        id: 'title',
        disablePadding: false,
        label: 'Tittel',
        canOrderBy: true
    },
    {
        id: 'createdAt',
        disablePadding: false,
        label: 'Opprettet',
        canOrderBy: true
    },
    {
        id: 'fileSize',
        disablePadding: false,
        label: 'Filstørrelse',
        canOrderBy: false
    },
]

export const FoldersTableHead = ({order, orderBy, sortHandler, pending=false}) => {
    const newOrder = () => order === "asc" ? "desc" : "asc";

    const createSortHandler = cellId => () => {
        sortHandler({
            order: orderBy === cellId ? newOrder() : "asc",
            orderBy: cellId
        })
    }

    const cellContent = headCell => {
        if(headCell.canOrderBy) {
            return (
                <TableSortLabel
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : 'asc'}
                    onClick={createSortHandler(headCell.id)}
                >
                    {headCell.label}
                    {orderBy === headCell.id ? (
                        <Box component={"span"} sx={visuallyHidden}>
                            {order === 'desc' ? 'Sortert synkende' : 'Sortert stigende'}
                        </Box>
                    ): null}
                </TableSortLabel>
            )
        } else {
            return headCell.label
        }

    }

    return (
        <TableHead>
            <TableRow>
                <TableCell
                    padding={'none'}
                >
                </TableCell>
                {headCells.map(headCell => (
                    <TableCell
                        key={headCell.id}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        {cellContent(headCell)}
                    </TableCell>
                ))}
                {/*<TableCell />*/}
            </TableRow>
            {pending && (
                <TableRow>
                    <TableCell colSpan={6} sx={{padding: 0}}>
                        <LinearProgress />
                    </TableCell>
                </TableRow>
            )}
        </TableHead>
    )
}