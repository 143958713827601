import {collection, getDocs, orderBy, query} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {FOLDERS_RECEIVED, useFolderDispatch, useFolderState} from "./folderContext";

export const useFolders = ({orderBy: byOrder='title', order='asc', folderIds=[], forceRefresh}) => {
    const {db} = useFirebaseState();
    const folderDispatch = useFolderDispatch()
    const {folders} = useFolderState()

    const folderIdsParams = folderIds.map(folder => [folder, 'folders']).flat(1)

    useDeepCompareEffect(() => {
        if(db) {
            const foldersRef = collection(db, 'folders', ...folderIdsParams);
            const q = query(foldersRef, orderBy(byOrder, order))
            getDocs(q)
                .then(querySnapshot => {
                    const tempDocs = []
                    querySnapshot.forEach(doc => {
                        tempDocs.push({...doc.data(), id: doc.id})
                    })
                    folderDispatch({
                        type: FOLDERS_RECEIVED,
                        folders: tempDocs
                    })
                })

        }
    }, [db, folderIdsParams, folderDispatch, orderBy, order, forceRefresh])

    return folders;
}