import {useMapEvents} from "react-leaflet";
import {useEffect} from "react";

export const AdminMap = ({position, clickHandler}) => {
    const map = useMapEvents({
        click: e => {
            clickHandler(e.latlng)
        }
    })

    useEffect(() => {
        if(position.length) {
            map.flyTo(position, 12)
        }
    }, [position[0], position[1]])

    return null;
}