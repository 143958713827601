import {useEffect, useState} from "react";
import {useFirebaseState} from "../firebase/firebaseContext";
import {collection, doc, getDocs, query, setDoc, where} from "firebase/firestore";

export const useMyInstructorProfile = ({userId=null}) => {
    const [instructor, setInstructor] = useState({});
    const [refresh, setRefresh] = useState()
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db && userId) {
            const instructorsRef = collection(db, 'instructors');

            const q = query(
                instructorsRef,
                where('owner', '==', userId)
            )

            getDocs(q)
            .then(querySnapshot => {
                if(querySnapshot.size > 0) {
                    let instructorData = querySnapshot.docs[0].data();
                    setInstructor({
                        ...instructorData,
                        id: querySnapshot.docs[0].id,
                        location: {
                            lat: instructorData.lat ? instructorData.lat : null,
                            lon: instructorData.lon ? instructorData.lon : null
                        }
                    })
                } else {
                    setInstructor({
                        owner: userId,
                        newProfile: true
                    })
                }
            })
        } else {
            setInstructor({});
        }
    }, [db, userId, setInstructor, refresh])

    const save = formData => {
        if(formData.id) {
            const instructorsRef = doc(db, 'instructors', formData.id)
            return setDoc(
                instructorsRef,
                {
                    address: formData.address,
                    county: formData.county,
                    description: formData.description,
                    email: formData.email,
                    name: formData.name,
                    phone: formData.phone,
                    place: formData.place,
                    title: formData.title,
                    lat: formData.location.lat,
                    lon: formData.location.lon,
                    published: false
                },
                {
                    merge: true
                }
            )
                .then(() => {
                    setRefresh(r => r + 1)
                    return Promise.resolve()
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        } else {
            const instructorsRef = collection(db, 'instructors')
            const newDoc = {
                address: formData.address,
                county: formData.county,
                description: formData.description,
                email: formData.email,
                name: formData.name,
                phone: formData.phone,
                place: formData.place,
                title: formData.title,
                lat: formData.location.lat,
                lon: formData.location.lon,
                published: false,
                owner: formData.owner
            }

            return setDoc(
                doc(instructorsRef),
                newDoc,
                {
                    merge: true
                }
            )
                .then(() => {
                    setRefresh(r => r + 1)
                    return Promise.resolve()
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        }
    }

    const publish = () => {
        const instructorsRef = doc(db, 'publishedInstructors', instructor.id)
        return setDoc(
            instructorsRef,
            {
                address: instructor.address,
                county: instructor.county,
                description: instructor.description,
                email: instructor.email,
                name: instructor.name,
                phone: instructor.phone,
                place: instructor.place,
                title: instructor.title,
                lat: instructor.location.lat,
                lon: instructor.location.lon,
                owner: userId
            },
            {
                merge: true
            }
        )
            .then(() => {
                const instructorsRef = doc(db, 'instructors', instructor.id)
                return setDoc(
                    instructorsRef,
                    {
                        published: true
                    },
                    {
                        merge: true
                    }
                ).then(() => {
                    setRefresh(r => r + 1)
                    return Promise.resolve()
                }).catch(error => {
                    return Promise.reject(error)
                })

            })
            .catch(error => {
                return Promise.reject(error)
            })
    }

    return {
        instructor: instructor,
        saveInstructor: save,
        publishInstructor: publish
    }
}