import React, {useEffect} from 'react';
import '../App.css';
import {HeaderProvider} from "../header/headerContext";
import {BrowserRouter} from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import {FirebaseProvider} from "../firebase/firebaseContext";
import {Header} from "../header/Header";
import {Content} from "./Content";
import {AppRoutes} from "./AppRoutes";
import {Menu} from "../menu/Menu";
import {Firebase} from '../firebase/Firebase';
import {Helmet} from "react-helmet";
import {grey} from '@mui/material/colors';
import {FileProvider} from "../file/fileContext";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        backgroundColor: grey[100],
        height: "100vh",
    }
}))

const App = () => {
    const classes = useStyles();

    return (
        <BrowserRouter>
            <Helmet>
                <title>Kurs i bekymringsmestring</title>
            </Helmet>

                <FirebaseProvider>
                    <Firebase>
                        <HeaderProvider>
                            <div className={classes.root}>
                                <Header/>
                                <Menu/>
                                <FileProvider>
                                    <Content>
                                        <AppRoutes/>
                                    </Content>
                                </FileProvider>
                            </div>
                        </HeaderProvider>
                    </Firebase>
                </FirebaseProvider>

        </BrowserRouter>
    );
}

export default App;
