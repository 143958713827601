import {getDownloadURL, getStorage, ref, uploadBytesResumable} from 'firebase/storage'
import {useState} from "react";

export const useFirebaseNewsUpload = () => {
    const [uploadProgress, setUploadProgress] = useState(0)

    const uploadFile = ({file, callback}) => {
        const storage = getStorage()
        const path = `/news-images/${file.name}`
        const fileRef = ref(storage, path)
        uploadBytesResumable(fileRef, file)
            .on(
                'state_changed',
                snapshot => {
                    setUploadProgress((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                },
                error => {
                    console.error(error)
                },
                () => {
                    getDownloadURL(fileRef)
                        .then(url => {
                            setUploadProgress(101)
                            callback(url)
                        })
                        .catch(error => {
                            console.error({error})
                        })
                }
            )
    }

    return {
        uploadFile,
        uploadProgress
    }
}