import React from 'react';
import {Button, Toolbar} from "@mui/material";
import {useFormikContext} from "formik";


export const FormActions = () => {
    const {submitForm, isSubmitting, isValid} = useFormikContext();

    return (
        <Toolbar>
            <Button
                type="submit"
                color={"primary"}
                variant={"contained"}
                onClick={submitForm}
                disabled={isSubmitting || !isValid}
            >
                Bestill
            </Button>
        </Toolbar>
    )
}