import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {collection, getDocs, orderBy, query} from "firebase/firestore";

export const useInstructors = ({orderBy: byOrder, order, forceRefresh, ready=true}) => {
    const [instructors, setInstructors] = useState([]);
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const instructorsRef = collection(db, 'instructors');
            const q = query(instructorsRef, orderBy(byOrder, order))
            getDocs(q)
                .then(querySnapshot => {
                    const tempInstructors = []
                    querySnapshot.forEach(doc => tempInstructors.push(({id: doc.id, ...doc.data()})))
                    setInstructors(tempInstructors)
                })

        }
    }, [db, setInstructors, orderBy, order, forceRefresh])

    return instructors;
}