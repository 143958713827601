import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import {useArticle} from "../firebase/useArticle";

export const DeleteArticle = ({open, articleId, handleClose}) => {
    const {
        article,
        deleteArticle
    } = useArticle(articleId)


    const deleteHandler = () => {
        deleteArticle(articleId)
            .then(() => {
                handleClose(true)
            }).catch(error => {
                console.error({error})
        })
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"xl"}
            fullWidth={true}
        >
            <DialogTitle>
                Slett nyhetsartikkel
            </DialogTitle>
            <DialogContent>
                Er du sikker på at du vil slette nyhetsartikkelen med tittelen: {article?.title}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"primary"}>
                    Avbryt
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={deleteHandler}
                >
                    Slett
                </Button>
            </DialogActions>
        </Dialog>
    )
}