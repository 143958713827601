import {useFirebaseState} from "../firebase/firebaseContext";
import {useIsInstructorOrAdmin} from "../firebase/useIsInstructorOrAdmin";
import {InstructorProfile} from "./InstructorProfile";

export const InstructorProfilePage = ({}) => {
    const {auth} = useFirebaseState()
    const instructorOrAdmin = useIsInstructorOrAdmin()


    if(!auth || !auth.currentUser || !instructorOrAdmin) {
        return null
    } else {
        return (
            <InstructorProfile
                userId={auth.currentUser.uid}
            />
        )
    }
}