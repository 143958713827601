import {doc, getDoc, setDoc} from 'firebase/firestore'

export const useCurrentUser = () => {

    const updateUser = ({db, userNow}) => {
        console.debug({userNow})
        const usersRef = doc(db, 'users', userNow.owner);
        getDoc(usersRef)
            .then(doc => {
                const currentRole = doc.data()?.role
                if(!currentRole) {
                    setDoc(
                        usersRef,
                        {
                            ...userNow,
                            role: "User"
                        },
                        {
                            merge: true
                        }
                    )
                    .then(() => {
                        console.debug('User updated');
                    })
                } else {
                    setDoc(
                        usersRef,
                        userNow,
                        {
                            merge: true
                        }
                    )
                        .then(() => {
                            console.debug('User updated');
                        })
                }
            })
    }

    return {
        updateUser
    }
}