import React from 'react'
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {useFirebaseState} from "../firebase/firebaseContext";
import {UserProvider} from "./userContext";
import {UsersContent} from "./UsersContent";
import {EditUser} from "./EditUser";

export const Users = () => {
    const {auth} = useFirebaseState()
    const isAdmin = useCheckIfAdmin()

    if(!auth || !auth.currentUser || !isAdmin) {
        return null
    } else {
        return (
            <UserProvider>
                <UsersContent />
                <EditUser />
            </UserProvider>
        )
    }
}