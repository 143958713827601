import {useUsers} from "./useUsers";
import {useUserState} from "./userContext";
import {UsersTable} from "./UsersTable";

export const UsersContent = () => {
    const {
        forceRefresh,
        users,
    } = useUserState()

    useUsers({
        forceRefresh: forceRefresh,
    })

    return (
        <UsersTable
            data={users}
        />
    )
}