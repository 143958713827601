import {useFirebaseState} from "../firebase/firebaseContext";
import {collection, doc, setDoc} from "firebase/firestore";
import {useState} from "react";

export const useGrantInstructor = () => {
    const {db} = useFirebaseState();
    const [refresh, setRefresh] = useState(0)

    const grantInstructorRole = ({user}) => {
        setDoc(
            doc(db, 'users', user.owner),
            {
                role: "Instructor"
            },
            {
                merge: true
            }
        ).then(() => {
            setRefresh(r => r + 1)
        })

    }

    const grantInstructor = ({user}) => {
        const instructorsRef = collection(db, "instructors");
        setDoc(
            doc(instructorsRef),
            {
                deleted: false,
                email: user.email,
                published: false,
                owner: user.owner
            }
        ).then(() => {
            setDoc(
                doc(db, 'users', user.owner),
                {
                    role: "Instructor"
                },
                {
                    merge: true
                }
            ).then(() => {
                setRefresh(r => r + 1)
            })
        })
    }

    const grantAndConnect = ({user, instructorId}) => {
        const instructorsRef = collection(db, 'instructors')
        setDoc(
            doc(instructorsRef, instructorId),
            {
                owner: user.owner
            },
            {
                merge: true
            }
        ).then(() => {
            if(user.role === "User"){
                setDoc(
                    doc(db, 'users', user.owner),
                    {
                        role: "Instructor"
                    },
                    {
                        merge: true
                    }
                ).then(() => {
                    setRefresh(r => r + 1)
                })
            } else {
                setRefresh(r => r + 1)
            }
        })
    }

    return {
        grantInstructor: grantInstructor,
        grantAndConnect: grantAndConnect,
        grantInstructorRole: grantInstructorRole,
        refresh: refresh
    }
}