import React from "react";
import {useFirebaseState} from "../firebase/firebaseContext";
import {updateProfile} from "firebase/auth";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {BasicProfileForm} from "./BasicProfileForm";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Save} from "@mui/icons-material";
import {FormSubmit} from "../form/FormSubmit";

export const EditProfile = ({open, onClose}) => {
    const {auth} = useFirebaseState()

    const handleSave = formData => {
        updateProfile(
            auth.currentUser,
            {
                displayName: formData.displayName,
                // photoURL: formData.photoURL
            }
        )
            .then(() => {
                console.debug("Profile updated")
                onClose()
            })
            .catch(error => {
                console.error({error})
            })
    }

    const validationSchema = Yup.object().shape({
        displayName: Yup.string()
            .required('Skriv inn navnet ditt'),
    })

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
        >
            <FormProvider
                schema={validationSchema}
                onSubmit={handleSave}
            >
                <DialogTitle>
                    Rediger profil
                </DialogTitle>
                <DialogContent>
                    <BasicProfileForm
                        user={auth.currentUser}
                    />
                </DialogContent>
                <DialogActions>
                    <FormSubmit
                        disabled={false}
                        variant={"contained"}
                        startIcon={<Save />}
                    >
                        Lagre
                    </FormSubmit>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}