import React from "react";
import Toolbar from "@mui/material/Toolbar";
import makeStyles from '@mui/styles/makeStyles';
import Container from "@mui/material/Container";
import {Box} from "@mui/material";
import {UploadFeedback} from "../file/UploadFeedback";

const useStyles = makeStyles(theme => ({
    main: {
        flexGrow: 1,
        width: "100%"
    },
}))

export const Content = ({children}) => {
    const classes = useStyles();


    return (
        <main className={classes.main}>
            <Toolbar />
            <Box
                sx={{
                    overflowY: "auto",
                    height: 'calc(100% - 64px)',
                    width: "100%"
                }}
            >
                <Container
                    sx={{
                        pt: {
                            xs: 0,
                            md: 2
                        }
                    }}
                    maxWidth={"lg"}
                >
                    {children}
                </Container>
            </Box>
            <UploadFeedback />
        </main>
    )
}