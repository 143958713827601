import React, {useState} from "react";
import {Box, styled, Toolbar} from "@mui/material";
import Paper from "@mui/material/Paper";
import {RichEditor} from "../admin/RichEditor";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import Button from "@mui/material/Button";
import {useMeditationContent} from "../firebase/useMeditationContent";
import {Helmet} from "react-helmet";

const Root = styled("div")(({theme}) => ({
    height: "100%"
}))

export const Meditation = () => {
    const [forceRefresh, setForceRefresh] = useState(0);
    const [meditationContent, updateMeditationContent] = useMeditationContent({forceRefresh: forceRefresh});
    const isAdmin = useCheckIfAdmin();
    const [editMode, setEditMode] = useState(false);

    const saveHandler = data => {
        updateMeditationContent(data);
        setForceRefresh(fr => fr +1);
        setEditMode(false);
    }

    const getContent = () => {
        if(editMode) {
            return <RichEditor
                initialData={meditationContent}
                onSave={saveHandler}
                onCancel={() => setEditMode(false)}
            />
        } else {
            return <div dangerouslySetInnerHTML={{__html: meditationContent}}/>
        }
    }


    const getAdminActions = () => {
        if(isAdmin) {
            return (
                <Toolbar>
                    <Box flexGrow={1} />
                    <Button variant={"contained"} color={"primary"} onClick={() => setEditMode(true)}>
                        Rediger
                    </Button>
                </Toolbar>
            )
        } else {
            return null;
        }
    }

    return (
        <Root>
            <Helmet>
                <title>Meditasjonsøvelser</title>
            </Helmet>
            <Paper
                sx={{
                    padding: {
                        xs: 2,
                        sm: 8
                    },
                    marginBottom: 4,
                }}
            >
                {getContent()}
                {getAdminActions()}
            </Paper>
        </Root>
    )
}