import React from "react";
import {Route, Routes} from "react-router";
import {About} from "./About";
import {LandingPage} from "./LandingPage";
import {ForRegularPersons} from "./ForRegularPersons";
import {Admin} from "../admin/Admin";
import {ForProfessionals} from "./ForProfessionals";
import {Meditation} from "./Meditation";
import {OrderBooks} from "../book/OrderBooks";
import {Email} from "../admin/Email";
import {Login} from "../header/Login";
import {Me} from "../profile/Me";
import {Folders} from "../folder/Folders";
import {Users} from "../user/Users";
import {InstructorProfilePage} from "../profile/InstructorProfilePage";

export const AppRoutes = () => {
    return (
        <>
            <Routes>
                <Route
                    exact
                    path={"/"}
                    element={<LandingPage />}
                />
                <Route
                    exact
                    path={"/about"}
                    element={<About />}
                />
                <Route
                    exact
                    path={"/find-instructor"}
                    element={<ForRegularPersons />}
                />
                <Route
                    exact
                    path={"/regular"}
                    element={<ForRegularPersons />}
                />
                <Route
                    exact
                    path={"/get-educated"}
                    element={<ForProfessionals />}
                />
                <Route
                    exact
                    path ="/meditation"
                    element={<Meditation />}
                />
                <Route
                    exact
                    path={"/order-books"}
                    element={<OrderBooks />}
                />
                <Route
                    exact
                    path={"/administrate-instructors"}
                    element={<Admin />}
                />
                <Route
                    exact
                    path={"/admin/users"}
                    element={<Users />}
                />
                <Route
                    exact
                    path={"/send-email-to-instructors"}
                    element={<Email />}
                />
                <Route
                    path={"/login"}
                    element={<Login />}
                />
                <Route
                    path={"/me"}
                    element={<Me />}
                />
                <Route
                    path={"/files"}
                    element={<Folders />}
                />
                <Route
                    path={"/instructor-profile"}
                    element={<InstructorProfilePage />}
                />
            </Routes>
        </>
    )
}