import {useNews} from "../firebase/useNews";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import {Box, Fab, Grid, IconButton, Paper, Stack} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import {formatRelative} from "date-fns";
import nbLocale from "date-fns/locale/nb";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {Add, Delete, Edit} from "@mui/icons-material";
import {EditArticle} from "./EditArticle";
import {DeleteArticle} from "./DeleteArticle";

const useStyles = makeStyles(theme => ({
    headerMedia: {
        height: 300,
        width: "100%",
        minWidth: 300,
        flexGrow: 1
    }
}));

export const News = () => {

    const isAdmin = useCheckIfAdmin();
    const [editArticle, setEditArticle] = React.useState(null)
    const [deleteArticle, setDeleteArticle] = React.useState(null)
    const [createArticle, setCreateArticle] = React.useState(false)
    const [forceRefresh, setForceRefresh] = useState(0)
    const news = useNews({
        orderBy: "published",
        order: "desc",
        forceRefresh: forceRefresh
    })

    const classes = useStyles()

    const editIcon = articleId => {
        if(isAdmin) {
            return (
                <>
                    <IconButton
                        onClick={() => setDeleteArticle(articleId)}
                        size={"large"}
                    >
                        <Delete />
                    </IconButton>
                    <IconButton onClick={() => setEditArticle(articleId)} size="large">
                        <Edit />
                    </IconButton>
                </>
            );
        } else {
            return null
        }
    }

    const fab = () => {
        if(isAdmin) {
            return (
                <Fab
                    color={"primary"}
                    sx={{
                        position: "absolute",
                        bottom: 16,
                        right: 16
                    }}
                    onClick={() => setCreateArticle(true)}
                >
                    <Add />
                </Fab>
            )
        } else {
            return null
        }
    }

    const getNewsImage = article => {
        if(article.media) {
            return (
                <img
                    src={article.media}
                    alt={article.title}
                    style={ {width: "100%", height: "100%"} }
                />
            )
        } else {
            return null
        }

    }

    return (
        <>
            <Grid
                container
                spacing={2}
                sx={{
                    mb: 2,
                }}
            >
                <Grid
                    item={true}
                    md={12}
                >
                    <Card
                        sx={{
                            p: 2
                        }}
                    >
                        <Grid container={true}>
                            <Grid
                                item={true}
                                md={6}
                            >
                            <CardMedia
                                className={classes.headerMedia}
                                image="/meditation.jpg"
                                title="Woman meditating"
                            />
                            </Grid>
                            <Grid
                                item={true}
                                md={6}
                            >
                                <CardContent>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        Bekymringsmestring
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        <Typography>
                                            Kurs i Bekymringsmestring har som formål å hjelpe mennesker til en mer avslappet hverdag. Kurset har dokumentert effekt.
                                        </Typography>
                                    </Typography>
                                </CardContent>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
                {news.map(article => (
                    <Grid
                        key={article.id}
                        item={true}
                        xs={12}

                    >
                        <Paper>
                            <Stack
                                sx={{
                                    padding: 4
                                }}
                                alignItems={"flex-start"}
                            >
                                <Stack
                                    direction={"row"}
                                    justifyContent={"space-between"}
                                    alignItems={"flex-start"}
                                    sx={{
                                        width: "100%"
                                    }}
                                >
                                    <Stack>
                                        <Typography variant="h5" component="h2" >
                                            {article.title}
                                        </Typography>
                                        <Typography variant={"overline"} gutterBottom={true}>
                                            {formatRelative(
                                                article.published.toDate(),
                                                new Date(),
                                                {
                                                    addSuffix: true, locale: nbLocale
                                                }
                                            )}
                                        </Typography>
                                    </Stack>
                                    <Box>
                                        {editIcon(article.id)}
                                    </Box>
                                </Stack>
                                <div dangerouslySetInnerHTML={{__html: article.text}}/>
                                {getNewsImage(article)}
                            </Stack>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
            <EditArticle
                open={null !== editArticle}
                articleId={editArticle}
                handleClose={(refresh) => {
                    if(refresh) {
                        setForceRefresh(fr => fr + 1)
                    }
                    setEditArticle(null)
                }}
            />
            <EditArticle
                open={createArticle}
                articleId={null}
                handleClose={(refresh) => {
                    if(refresh) {
                        setForceRefresh(fr => fr + 1)
                    }
                    setCreateArticle(false)
                }}
            />
            <DeleteArticle
                open={null !== deleteArticle}
                articleId={deleteArticle}
                handleClose={(refresh) => {
                    if(refresh) {
                        setForceRefresh(fr => fr + 1)
                    }
                    setDeleteArticle(null)
                }}
            />
            {fab()}
        </>
    )
}