import {Button} from "@mui/material";
import React from "react";
import {useFormState} from "../form/formContext";
import {isEqual} from "lodash";

export const PublishButton = ({published, newProfile, onClick}) => {
    const {isSubmitting, errors, formData, initialFormData} = useFormState()
    const isModified = !isEqual(formData, initialFormData)
    return (
        <Button
            variant={"contained"}
            disabled={published || isSubmitting || isModified || newProfile}
            onClick={onClick}
        >
            Publiser meg på siden
        </Button>
    )
}