import {Step, StepButton, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import {useFormikContext} from "formik";

export const BookOrderStepper = ({activeStep, changeStep, children}) => {
    const {errors} = useFormikContext();
    const getSteps = () => {
        return [
            "Bestiller",
            "Antall",
            "Levering",
            "Betaling",
        ]
    }

    const getErrors = step => {
        switch(step) {
            case 0:
                return errors.name || errors.email || errors.phone
            case 2:
                return errors.addressAreaCode || errors.addressName || errors.addressPlace || errors.addressStreet ||
                    errors.contactPerson || errors.contactPhone
            case 3:
                return errors.invoiceName || errors.invoiceAddress || errors.invoiceAreaCode || errors.invoicePlace
            default:
                return false
        }
    }

    const getOptionalText = step => {
        if(getErrors(step)) {
            return (
                <Typography variant={"caption"} color={"error"}>
                    Påkrevd
                </Typography>
            )
        } else {
            return null;
        }
    }

    return (
        <Stepper nonLinear activeStep={activeStep} orientation={"vertical"}>
            {getSteps().map((label, index) => (
                <Step key={label}>
                    <StepButton
                        onClick={() => changeStep(index)}
                        optional={getOptionalText(index)}
                    >
                        {label}
                    </StepButton>
                    <StepContent>
                        {children}
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    )
}