import {Paper, styled, Typography} from "@mui/material";
import React, {useState} from "react";
import {BookOrderForm} from "./BookOrderForm";
import {Helmet} from "react-helmet";


const Root = styled("div")(({theme}) => ({
    height: "100%"
}))

export const OrderBooks = () => {
    const [ordered, setOrdered] = useState(false);

    const getSchemaOrReceipt = () => {
        if(ordered) {
            return <Typography>Bøker er bestilt. Kvittering er sendt på epost.</Typography>;
        } else {
            return <BookOrderForm onClose={() => setOrdered(true)}/>
        }
    }
    return (
        <Root>
            <Helmet>
                <title>Bestill kursbøker</title>
            </Helmet>
            <Paper
                sx={{
                    padding: {
                        xs: 2,
                        sm: 8
                    },
                    marginBottom: 4,
                }}
            >
                <Typography component={"h2"} variant={"h6"}>
                    Bestilling av arbeidsbøker til Kurs i Bekymringsmestring
                </Typography>
                {getSchemaOrReceipt()}
            </Paper>
        </Root>
    )
}