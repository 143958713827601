import React from "react";
import {TableBody, TableCell, TableRow} from "@mui/material";
import {FolderTableRow} from "./FolderTableRow";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {PARENT_FOLDERS_SET, SELECTED, useFolderDispatch, useFolderState} from "./folderContext";

export const FoldersTableBody = ({rows,  emptyRows=0, }) => {
    const isAdmin = useCheckIfAdmin()
    const {
        parentFolders,
        selected
    } = useFolderState()
    const folderDispatch = useFolderDispatch()

    const rowFiller = () => {
        if(0 < emptyRows) {
            return (
                <TableRow
                    sx={{
                        height: 105 * emptyRows
                    }}
                >
                    <TableCell colSpan={6} />
                </TableRow>
            )
        } else {
            return null
        }
    }

    const onClick = folder => {
        if(selected?.id === folder.id) {
            if("folder" === folder.type) {
                folderDispatch({
                    type: PARENT_FOLDERS_SET,
                    parentFolders: [...parentFolders, folder]
                })
            }
        } else {
            folderDispatch({
                type: SELECTED,
                selected: folder
            })
        }
    }

    if(rows) {
        return (
            <TableBody>
                {rows.map(row => (
                    <FolderTableRow
                        key={row.id}
                        row={row}
                        onClick={() => onClick(row)}
                        isAdmin={isAdmin}
                        selected={selected?.id === row.id}
                    />
                ))}
                {rowFiller()}
            </TableBody>
        )
    } else {
        return null
    }

}