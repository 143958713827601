import React from "react";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Save} from "@mui/icons-material";
import {FormSubmit} from "../form/FormSubmit";
import {FolderForm} from "./FolderForm";
import {REFRESH_FORCED, useFolderDispatch} from "./folderContext";
import {useCreateFolder} from "./useCreateFolder";

export const CreateFolder = ({open, onClose}) => {

    const folderDispatch = useFolderDispatch()
    const {create} = useCreateFolder({})

    const handleSave = formData => {
        const timestamp = new Date();
        create({
            ...formData,
            createdAt: timestamp,
            type: "folder",
            modifiedAt: timestamp
        })
        .then(() => {
            onClose()
            folderDispatch({type: REFRESH_FORCED})
        })
        .catch(error => console.error(error))
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string()
            .required('Skriv inn navnet på mappen'),
    })

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
        >
            <FormProvider
                schema={validationSchema}
                onSubmit={handleSave}
            >
                <DialogTitle>
                    Ny mappe
                </DialogTitle>
                <DialogContent>
                    <FolderForm />
                </DialogContent>
                <DialogActions>
                    <FormSubmit
                        disabled={false}
                        variant={"contained"}
                        startIcon={<Save />}
                    >
                        Lagre
                    </FormSubmit>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}