import React, {useEffect} from 'react';
import RichTextEditor from 'react-rte';
import {Box, Button, Typography} from "@mui/material";
import Toolbar from "@mui/material/Toolbar";
export const RichEditor = ({initialData, onSave, onCancel}) => {
    const [editorState, setEditorState] = React.useState(
        () => RichTextEditor.createEmptyValue()
    );

    useEffect(() => {
        if(initialData) {
            setEditorState(RichTextEditor.createValueFromString(initialData, 'html'));
        }
    }, [initialData, setEditorState])

    return (
        <>
            <RichTextEditor
                value={editorState}
                onChange={setEditorState}
            />
            <Toolbar>
                <Box flexGrow={1} />
                <Button color={"primary"} onClick={onCancel}>
                    Avbryt
                </Button>
                <Button variant="contained" color="primary" onClick={() => onSave(editorState.toString('html'))}>
                    Lagre
                </Button>
            </Toolbar>
            <Typography variant={"h6"} component={"h3"}>
                Preview
            </Typography>
            <div dangerouslySetInnerHTML={{__html: editorState.toString('html')}}/>
        </>
    );
}