import {useEffect, useState} from "react";
import {collection, getDocs, orderBy, query, where} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";

export const useUnclaimedInstructors = ({orderBy: byOrder="name", order="asc", forceRefresh, ready=true}) => {
    const [instructors, setInstructors] = useState([]);
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db && ready) {
            const instructorsRef = collection(db, 'instructors');
            const q = query(
                instructorsRef,
                orderBy(byOrder, order)
            )
            getDocs(q)
                .then(querySnapshot => {
                    const tempInstructors = []
                    querySnapshot.forEach(doc => tempInstructors.push(({id: doc.id, ...doc.data()})))
                    setInstructors(
                        tempInstructors
                            .filter(instructor => !instructor.owner)
                    )
                })

        }
    }, [db, setInstructors, orderBy, order, forceRefresh, ready])

    return instructors;
}