import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {collection, query, getDocs, orderBy} from "firebase/firestore";

export const usePublishedInstructors = ({orderBy: byOrder='name', order='asc', forceRefresh=0}) => {
    const [publishedInstructors, setPublishedInstructors] = useState([]);
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const publishedInstructorsRef = collection(db, 'publishedInstructors');
            const q = query(publishedInstructorsRef, orderBy(byOrder, order))
            getDocs(q)
                .then(querySnapshot => {
                    const tempInstructors = []
                    querySnapshot.forEach(doc => tempInstructors.push(({id: doc.id, ...doc.data()})))
                    setPublishedInstructors(tempInstructors)
                })

        }
    }, [db, setPublishedInstructors, byOrder, order, forceRefresh])

    return publishedInstructors;
}