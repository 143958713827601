import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";


export const InstructorDetails = ({instructor, handleClose}) => {

    const getContent = () => {
        if(instructor) {
            return (
                <>
                    <Typography variant={"body2"}>{instructor.title}</Typography>
                    <Typography>{instructor.description}</Typography>
                    <Typography>Sted: {instructor.place}</Typography>
                    <Typography>Fylke: {instructor.county}</Typography>
                    <Typography>
                        Send epost: <a href={`mailto:${instructor.email}`}>{instructor.email}</a>
                    </Typography>
                    <Typography>
                        Ring: <a href={`tel:${instructor.phone}`}>{instructor.phone}</a>
                    </Typography>
                </>
            )
        } else {
            return null
        }
    }

    return (
        <Dialog
            open={Boolean(instructor)}
            onClose={handleClose}
        >
            <DialogTitle>
                {instructor?.name}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {getContent()}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" color={"primary"} onClick={handleClose}>
                    Lukk
                </Button>
            </DialogActions>
        </Dialog>
    )

}