import React, {useState} from 'react'
import {Alert, Box, Button, Grid, Paper, Snackbar, Stack} from "@mui/material";
import {useMyInstructorProfile} from "./useMyInstructorProfile";
import {Form} from "../form/Form";
import {FormProvider} from "../form/formContext";
import {InstructorForm} from "./InstructorForm";
import * as Yup from "yup";
import {InstructorMap} from "./InstructorMap";
import {FormSubmit} from "../form/FormSubmit";
import {InitializeForm} from "../form/InitializeForm";
import {PublishButton} from "./PublishButton";

export const InstructorProfile = ({userId}) => {
    const {
        instructor,
        saveInstructor,
        publishInstructor
    } = useMyInstructorProfile({
        userId: userId,
    })

    const [feedback, setFeedback] = useState([])

    const onPublish = () => {
        publishInstructor()
            .then(() => {
                setFeedback(
                    f =>[
                        ...f,
                        {
                            severity: 'success',
                            message: "Profilen ble publisert"
                        }
                    ]
                )
            })
            .catch(error => {
                console.error({error})
                setFeedback(
                    f =>[
                        ...f,
                        {
                            severity: 'error',
                            message: "Noe gikk galt ved publisering"
                        }
                    ]
                )
            })

    }

    const onSubmit = formData => {
        saveInstructor({
            ...formData,
            id: instructor.id,
            owner: instructor.owner
        })
            .then(() => {
                    setFeedback(
                        f =>[
                            ...f,
                            {
                                severity: 'success',
                                message: "Profilen ble oppdatert"
                            }
                        ]
                    )
            })
            .catch(error => {
                console.error({error})
                    setFeedback(
                        f =>[
                            ...f,
                            {
                                severity: 'error',
                                message: "Noe gikk galt ved lagring"
                            }
                        ]
                    )
            })
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Skriv inn navnet ditt'),
        title: Yup.string()
            .required("Skriv inn din jobbtittel"),
        description: Yup.string(),
        email: Yup.string()
            .required('Skriv inn e-postadressen din'),
        phone: Yup.string(),
        address: Yup.string()
            .required("Skriv inn addressen kurset holdes på"),
        place: Yup.string()
            .required("Skriv inn stedet kurset holdes på."),
        county: Yup.string()
            .required("Velg kommunen kurset holdes på."),
        location: Yup.object().shape({
            lat: Yup.number(),
            lon: Yup.number()
        })
    })

    if(!instructor?.owner) {
        return null
    } else {
        return (
            <FormProvider
                onSubmit={onSubmit}
                schema={validationSchema}
            >
                <InitializeForm
                    initialValues={instructor}
                />
                <Paper
                    sx={{
                        padding: 4,
                        mb: 2
                    }}
                >
                    <Grid
                        container={true}
                        spacing={2}
                        sx={{
                            mb: 4
                        }}
                    >
                        <Grid
                            item={true}
                            sm={12}
                            md={6}
                        >
                            <Form>
                                <InstructorForm />
                            </Form>
                        </Grid>
                        <Grid
                            item={true}
                            sm={12}
                            md={6}
                        >
                            <InstructorMap
                                formKey={"location"}
                            />
                        </Grid>
                        <Grid
                            item={true}
                            xs={12}
                        >
                            <Stack
                                direction={"row"}
                                spacing={2}
                            >
                                <Box
                                    flexGrow={1}
                                />
                                <FormSubmit
                                    variant={"contained"}
                                    color={"primary"}
                                >
                                    Lagre
                                </FormSubmit>
                                <PublishButton
                                    published={instructor.published}
                                    newProfile={instructor.newProfile}
                                    onClick={onPublish}
                                />
                            </Stack>
                        </Grid>
                    </Grid>
                </Paper>
                <Snackbar
                    open={feedback.length > 0}
                    autoHideDuration={6000}
                    onClose={() => setFeedback([])}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                >
                    <Alert severity={feedback[0]?.severity}>
                        {feedback[0]?.message}
                    </Alert>
                </Snackbar>
            </FormProvider>
        )
    }
}