import {IconButton, InputAdornment, Stack, Typography} from "@mui/material";
import {Form} from "../form/Form";
import React, {useEffect, useState} from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {Login, Visibility, VisibilityOff} from "@mui/icons-material";
import {FormSubmit} from "../form/FormSubmit";
import {FORM_INITIALIZED, useFormDispatch, useFormState} from "../form/formContext";

export const EmailPasswordForm = ({disabled=false, loginFailed=false}) => {
    const formDispatch = useFormDispatch()
    const [showPassword, setShowPassword] = useState(false)
    const {initialized} = useFormState()

    useEffect(
        () => {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    username: '',
                    password: ''
                }
            })
        },[formDispatch]
    )

    const failedLoginInfo = () => {
        if(loginFailed) {
            return (
                <Typography variant="body2" color="error">
                    Innlogging feilet. Sjekk at epost og passord er riktig.
                </Typography>
            )
        } else {
            return null
        }

    }

    if(!initialized) {
        return null
    }


    return (
        <Form>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{
                    mt: 2
                }}
            >
                <TextFieldForm
                    formKey={'username'}
                    label={'E-post'}
                    ariaLabel={'Skriv inn e-posten din'}
                    fullWidth={true}
                    autoFocus={true}
                    disabled={Boolean(disabled)}
                />

                <TextFieldForm
                    formKey={'password'}
                    label={'Passord'}
                    ariaLabel={'Skriv inn passordet ditt'}
                    fullWidth={true}
                    type={showPassword ? 'text' : 'password'}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(!showPassword)}
                                    onMouseDown={e => e.preventDefault()}
                                    tabIndex={-1}
                                    size="large">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    disabled={Boolean(disabled)}
                />
                {failedLoginInfo()}
                <FormSubmit
                    disabled={disabled}
                    variant={"contained"}
                    startIcon={<Login />}
                >
                    Logg inn
                </FormSubmit>
            </Stack>
        </Form>
    )
}