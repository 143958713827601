import {Paper, Slide} from "@mui/material";
import {useFolderState} from "./folderContext";

export const FileDetailsSideSheet = ({children}) => {
    const {selected} = useFolderState()

    const open = null !== selected && selected.type !== "folder"

    return (
        <Slide
            direction={"left"}
            in={open}
            mountOnEnter={true}
            unmountOnExit={true}
        >
            <Paper
                sx={{
                    padding: 2
                }}
            >
                {children}
            </Paper>
        </Slide>
    )
}