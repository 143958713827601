import React from "react";
import {Box} from "@mui/material";


export const StepContent = ({children}) => {

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column"
            }}
        >
            {children}
        </Box>
    )
}