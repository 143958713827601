import React from 'react'

export const REFRESH_FORCED = 'REFRESH_FORCED'
export const PARENT_FOLDERS_SET = 'PARENT_FOLDERS_SET'
export const SELECTED = 'SELECTED'
export const DESELECTED = 'DESELECTED'
export const DRAG_TARGET_SELECTED = 'DRAG_TARGET_SELECTED'
export const DRAG_TARGET_DESELECTED = 'DRAG_TARGET_DESELECTED'
export const FOLDERS_RECEIVED = 'FOLDERS_RECEIVED'

const FolderStateContext = React.createContext(undefined)
const FolderDispatchContext = React.createContext(undefined)

const initialState = {
    forceRefresh: 0,
    parentFolders: [],
    selected: null,
    dropTarget: [],
    folders: []
}

const folderReducer = (state, action) => {
    switch (action.type) {
        case REFRESH_FORCED:
            return {
                ...state,
                forceRefresh: state.forceRefresh + 1
            }
        case PARENT_FOLDERS_SET:
            return {
                ...state,
                parentFolders: action.parentFolders,
                selected: null
            }
        case SELECTED:
            return {
                ...state,
                selected: action.selected
            }
        case DESELECTED:
            return {
                ...state,
                selected: null
            }
        case DRAG_TARGET_SELECTED:
            return {
                ...state,
                dropTarget: [
                    ...state.dropTarget,
                    action.dropTarget
                ]
            }
        case DRAG_TARGET_DESELECTED:
            return {
                ...state,
                dropTarget: state.dropTarget.filter(target => target.id !== action.dropTarget.id)
            }
        case FOLDERS_RECEIVED:
            return {
                ...state,
                folders: action.folders
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const FolderProvider = ({ children }) => {
    const [state, dispatch] = React.useReducer(folderReducer, initialState, undefined)

    return (
        <FolderStateContext.Provider value={state}>
            <FolderDispatchContext.Provider value={dispatch}>
                {children}
            </FolderDispatchContext.Provider>
        </FolderStateContext.Provider>
    )
}

export const useFolderState = () => {
    const context = React.useContext(FolderStateContext)
    if (context === undefined) {
        throw new Error('useFolderState must be used within a FolderProvider')
    } else {
        return context
    }
}

export const useFolderDispatch = () => {
    const context = React.useContext(FolderDispatchContext)
    if (context === undefined) {
        throw new Error('useFolderDispatch must be used within a FolderProvider')
    } else {
        return context
    }
}