import React from 'react'
import {Dialog, DialogContent, Slide} from "@mui/material";
import {DESELECTED, useFolderDispatch, useFolderState} from "./folderContext";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
})

export const FileDetailsDialog = ({children}) => {
    const {selected} = useFolderState()
    const folderDispatch = useFolderDispatch()

    const closeHandler = () => {
        folderDispatch({
            type: DESELECTED
        })
    }

    const open = null !== selected && selected.type !== "folder"

    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted={true}
            onClose={closeHandler}
        >
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
    )
}