import {Stack} from "@mui/material";
import {Form} from "../form/Form";
import React, {useEffect} from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {FormSubmit} from "../form/FormSubmit";
import {FORM_INITIALIZED, useFormDispatch, useFormState} from "../form/formContext";

export const ForgotPasswordForm = ({disabled=false}) => {
    const formDispatch = useFormDispatch()
    const {initialized} = useFormState()

    useEffect(
        () => {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    username: ''
                }
            })
        },[formDispatch]
    )

    if(!initialized) {
        return null
    }

    return (
        <Form>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{
                    mt: 2
                }}
            >
                <TextFieldForm
                    formKey={'username'}
                    label={'E-post'}
                    ariaLabel={'Skriv inn e-posten din'}
                    fullWidth={true}
                    autoFocus={true}
                    disabled={Boolean(disabled)}
                />
                <FormSubmit
                    disabled={disabled}
                    variant={"contained"}
                >
                    Send lenke
                </FormSubmit>
            </Stack>
        </Form>
    )
}