import React from 'react';
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material";


const Root = styled("div")(({theme}) => ({
    display: 'flex',
    '& > * + *': {
        ml: 2
    },
}));

export const WithLoadingProgress = ({loading, children}) => {

    if(loading) {
        return (
            <Root>
                <CircularProgress />
            </Root>
        );
    } else {
        return (
            <>
                {children}
            </>
        )
    }
}