import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {useFolderState} from "./folderContext";
import {IconButton} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {DeleteFolder} from "./DeleteFolder";
import React from "react";

export const AdminActions = () => {
    const {
        selected
    } = useFolderState()
    const [deleteFolder, setDeleteFolder] = React.useState(false)

    const isAdmin = useCheckIfAdmin()

    if(isAdmin && null !== selected) {
        return (
            <>
                <IconButton
                    onClick={() => setDeleteFolder(true)}
                >
                    <Delete />
                </IconButton>
                <DeleteFolder
                    open={Boolean(deleteFolder)}
                    onClose={() => setDeleteFolder(null)}
                    folder={selected}
                    folderIsFile={selected.type !== "folder"}
                />
            </>
        )
    } else {
        return null
    }
}