import React, {useRef} from 'react'
import {Box, ListItemIcon, ListItemText, Menu, MenuItem, Stack} from "@mui/material";
import {BreadCrumbButton} from "./BreadCrumbButton";
import {CreateNewFolder, FolderDelete, UploadFile} from "@mui/icons-material";
import {CreateFolder} from "./CreateFolder";
import {PARENT_FOLDERS_SET, useFolderDispatch, useFolderState} from "./folderContext";
import {DeleteFolder} from "./DeleteFolder";
import {AdminActions} from "./AdminActions";
import {useFirebaseFileUpload} from "../firebase/useFirebaseFileUpload";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";

export const FoldersToolbar = () => {
    const {
        parentFolders,
    } = useFolderState()

    const folderDispatch = useFolderDispatch()
    const isAdmin = useCheckIfAdmin()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [createFolder, setCreateFolder] = React.useState(false)
    const [deleteFolder, setDeleteFolder] = React.useState(false)
    const inputFile = useRef(null)
    const {uploadFile} = useFirebaseFileUpload({})

    const onClick = (event, isLast, index) => {
        if(isLast) {
            setAnchorEl(event.currentTarget)
        } else {
            folderDispatch({
                type: PARENT_FOLDERS_SET,
                parentFolders: parentFolders.slice(0, index + 1)
            })
        }
    }

    const menuOpen = Boolean(anchorEl)

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleCreateFolder = () => {
        setCreateFolder(true)
        handleCloseMenu()
    }

    const handleUploadFiles = () => {
        handleCloseMenu()
        inputFile.current.click()
    }

    const onFilesSelected = event => {
        event.stopPropagation()
        event.preventDefault()
        const files = [...event.target.files]
        files.forEach(file => {
            uploadFile(file)
        })
    }

    const handleDeleteFolder = () => {
        setDeleteFolder(true)
        handleCloseMenu()
    }

    return (
        <Stack
            direction="row"
            justifyContent={"space-between"}
            alignItems={"center"}
            spacing={2}
            sx={{
                padding: 2
            }}
        >
            <Stack
                direction="row"
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={2}
            >
                <BreadCrumbButton
                    isLast={parentFolders.length === 0}
                    title={"Filer"}
                    onClick={event => onClick(event,parentFolders.length === 0, -1)}
                />
                {parentFolders.map((folder, index) => (
                    <BreadCrumbButton
                        key={index}
                        isLast={index === parentFolders.length - 1}
                        title={folder.title}
                        onClick={event => onClick(event,index === parentFolders.length - 1, index)}
                    />
                ))}
                <Menu
                    id="folder-menu"
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                >
                    <MenuItem
                        onClick={handleCreateFolder}
                        disabled={!isAdmin}
                    >
                        <ListItemIcon>
                            <CreateNewFolder />
                        </ListItemIcon>
                        <ListItemText>
                            Ny mappe
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={handleUploadFiles}
                        disabled={!isAdmin}
                    >
                        <ListItemIcon>
                            <UploadFile />
                        </ListItemIcon>
                        <ListItemText>
                            Last opp filer
                        </ListItemText>
                    </MenuItem>
                    <MenuItem
                        onClick={handleDeleteFolder}
                        disabled={!isAdmin || parentFolders.length === 0}
                    >
                        <ListItemIcon>
                            <FolderDelete />
                        </ListItemIcon>
                        <ListItemText>
                            Slett mappe
                        </ListItemText>
                    </MenuItem>
                </Menu>
                <CreateFolder
                    open={createFolder}
                    onClose={() => setCreateFolder(false)}
                />
                <DeleteFolder
                    open={Boolean(deleteFolder)}
                    onClose={() => setDeleteFolder(null)}
                />
                <input
                    type='file'
                    id='file'
                    ref={inputFile}
                    style={{display: 'none'}}
                    multiple={true}
                    onChange={onFilesSelected}
                />
            </Stack>
            <Stack
                direction="row"
                justifyContent={"flex-start"}
                alignItems={"flex-start"}
                spacing={2}
            >
                <AdminActions />
            </Stack>
        </Stack>
    )
}