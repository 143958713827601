import {FormikTextField} from "../form/FormikTextField";
import React from "react";
import {Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useFormikContext} from "formik";


export const InvoiceStep = () => {
    const {values, setFieldValue, setFieldTouched} = useFormikContext();

    return (
        <>
            <Typography
                variant={"body2"}
                gutterBottom={true}
            >
                Oppgi fakturaadresse. Husk organisasjonsnummer og referanse dersom du bestiller for en bedrift
            </Typography>
            <Button
                color={"primary"}
                onClick={() => {
                    setFieldValue("invoiceName", values.addressName)
                        .then(() => setFieldTouched("invoiceName"))

                    setFieldValue("invoiceAddress", values.addressStreet)
                        .then(() => setFieldTouched("invoiceAddress"))

                    setFieldValue("invoiceAreaCode", values.addressAreaCode)
                        .then(() => setFieldTouched("invoiceAreaCode"))

                    setFieldValue("invoicePlace", values.addressPlace)
                        .then(() => setFieldTouched("invoicePlace"))

                }}
            >
                Kopier fra levering
            </Button>
            <FormikTextField
                formikKey={"invoiceName"}
                label={"Navn / firma"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"invoiceOrgNumber"}
                label={"Organisasjonsnummer"}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"invoiceReference"}
                label={"Referanse"}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"invoiceAddress"}
                label={"Adresse"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"invoiceAreaCode"}
                label={"Postnummer"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"invoicePlace"}
                label={"Poststed"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
        </>
    )
}