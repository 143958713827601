import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {doc, getDoc, updateDoc, deleteDoc} from "firebase/firestore";

export const usePublishedInstructor = id => {
    const [publishedInstructor, setPublishedInstructor] = useState({});
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db && id) {
            const publishedInstructorsRef = doc(db, 'publishedInstructors', id);
            getDoc(publishedInstructorsRef)
                .then(doc => {
                    if(doc.exists) {
                        setPublishedInstructor(doc.data())
                    }
                })

        }
    }, [db, id, setPublishedInstructor])

    const updatePublishedInstructor = (id, updatedInstructor) => {
        if(db && id) {
            const publishedInstructorsRef = doc(db, 'publishedInstructors', id);
            return updateDoc(
                publishedInstructorsRef,
                updatedInstructor
            )
        }
    }

    const deletePublishedInstructor = id => {
        if(db && id) {
            const publishedInstructorsRef = doc(db, 'publishedInstructors', id);
            deleteDoc(publishedInstructorsRef)
                .then(() => console.debug('deleted'))
        }
    }

    return [publishedInstructor, updatePublishedInstructor, deletePublishedInstructor];
}