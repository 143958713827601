import {Box, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {CloudUpload, Folder} from "@mui/icons-material";
import {useActiveDropTarget} from "./useActiveDropTarget";
import { keyframes } from '@emotion/react';

const getAnimation = keyframes`
    0% {
        transform: scale(1, 1) translateY(0);
    }
    10% {
        transform: scale(1.1, 0.9) translateY(0);
    }
    30% {
        transform: scale(0.9, 1.1) translateY(-30px);
    }
    50% {
        transform: scale(1.05, 0.95) translateY(0);
    }
    57% {
        transform: scale(1,1) translateY(-7px);
    }
    64% {
        transform: scale(1,1) translateY(0);
    }
    100% {
        transform: scale(1,1) translateY(0);
    }
`;

export const ActiveDropTarget = () => {
    const dropTarget = useActiveDropTarget()

    return (
        <Snackbar
            open={Boolean(dropTarget)}
            onClose={() => {}}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
        >
            <Box>
                <Stack
                    direction={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    spacing={1}
                >
                    <CloudUpload
                        color={"primary"}
                        sx={{
                            animation: `${getAnimation} 1.5s linear infinite`,
                            height: 96,
                            width: 96
                        }}
                    />
                    <Paper
                        sx={{
                            backgroundColor: theme => theme.palette.primary.main,
                            color: "white",
                        }}
                    >
                        <Stack
                            sx={{
                                margin: 4
                            }}
                            direction={"column"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Typography>
                                Slipp for å laste opp filene til:
                            </Typography>
                            <Stack
                                direction={"row"}
                                spacing={1}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Folder />
                                <Typography
                                    fontWeight={"bold"}
                                >
                                    {dropTarget?.title}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Paper>
                </Stack>
            </Box>
        </Snackbar>
    )
}