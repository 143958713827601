import {FormikTextField} from "../form/FormikTextField";
import React from "react";
import {Typography} from "@mui/material";
import {useFormikContext} from "formik";
import Button from "@mui/material/Button";


export const RecipientStep = () => {
    const {values, setFieldValue, setFieldTouched} = useFormikContext();
    return (
        <>
            <Typography
                variant={"body2"}
                gutterBottom={true}
            >
                Hvor skal bøkene leveres? Ved bestilling til offentlige kontor hvor det er folk til stede på dagtid
                så leveres bøkene direkte på døren. Husk derfor å oppgi gateadresse / besøksadresse og IKKE
                postboksadresse. Dersom du oppgir en privat adresse så vil bøkene leveres til ditt nårmeste
                postkontor / post i butikk, og du vil motta hentelapp i posten.
            </Typography>
            <FormikTextField
                formikKey={"addressName"}
                label={"Navn / firma"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"addressStreet"}
                label={"Gate og husnummer"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"addressAreaCode"}
                label={"Postnummer"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"addressPlace"}
                label={"Poststed"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <Typography
                variant={"body2"}
                gutterBottom={true}
            >
                Oppgi navn og telefonnummer til en kontaktperson som posten kan kontakte vedrørende leveringen
                dersom det skulle være  behov for dette.
            </Typography>
            <Button
                color={"primary"}
                onClick={() => {
                    setFieldValue("contactPerson", values.name)
                        .then(() => setFieldTouched("contactPerson"))
                    setFieldValue("contactPhone", values.phone)
                        .then(() => setFieldTouched("contactPhone"))
                }}
            >
                Kopier fra bestiller
            </Button>
            <FormikTextField
                formikKey={"contactPerson"}
                label={"Navn"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
            <FormikTextField
                formikKey={"contactPhone"}
                label={"Telefonnummer"}
                required={true}
                sx={{
                    mb: 2
                }}
            />
        </>
    )
}