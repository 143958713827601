import React, {useState} from 'react';
import {InstructorsMap} from "../instructors/InstructorsMap";
import {Tab, Tabs, Toolbar, Typography} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Paper from "@mui/material/Paper";
import {InstructorsList} from "../instructors/InstructorsList";
import Hidden from "@mui/material/Hidden";
import {Helmet} from "react-helmet";

const useStyles = makeStyles(theme => ({
    root: {
        height: "100",
    },
}))

export const ForRegularPersons = () => {
    const [activeTab, setActiveTab] = useState(0);
    const classes = useStyles();


    const getMapOrList = () => {
        if(0 === activeTab) {
            return <InstructorsMap />;
        } else {
            return <InstructorsList />;
        }
    }

    return (
        <div className={classes.root}>
            <Helmet>
                <title>Oversikt over kursholdere</title>
            </Helmet>
            <Paper
                sx={{
                    p: 2
                }}
            >
                <Typography variant={"h5"} component={"h2"} gutterBottom={true}>
                    Oversikt over sertifiserte kursholdere
                </Typography>
                <Typography>
                    Her finner du en oversikt over sertifiserte kursholdere som har registrert seg. Det finnes flere kursholdere enn de som har valgt å registrere seg her, så om du ikke finner noen i nærheten av der du bor så kan det likevel være verdt å ta kontakt med din hjemkommune for å høre om de tilbyr kurset.
                </Typography>
                <Tabs
                    value={activeTab}
                    indicatorColor={"primary"}
                    textColor={"primary"}
                    onChange={(event, value) => setActiveTab(value)}
                >
                    <Tab label="Kart" />
                    <Tab label="Liste" />
                </Tabs>
                {getMapOrList()}
            </Paper>
            <Hidden mdDown>
                <Toolbar/>
            </Hidden>
        </div>
    );
}