import React, {useEffect, useState} from 'react';
import {useInstructor} from "../firebase/useInstructor";
import TextField from "@mui/material/TextField";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    Hidden,
    InputLabel,
    MenuItem,
    Select,
    Tab,
    Tabs,
    useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {EditInstructorMap} from "./EditInstructorMap";
import {WithLoadingProgress} from "../app/WithLoadingProgress";
import {usePublishedInstructor} from "../firebase/usePublishedInstructor";


const counties = [
    "Agder",
    "Innlandet",
    "Møre og Romsdal",
    "Nordland",
    "Oslo",
    "Rogaland",
    "Vestfold og Telemark",
    "Troms og Finnmark",
    "Trøndelag",
    "Vestland",
    "Viken"
]

export const EditInstructor = ({open, handleClose, instructorId}) => {
    const [activeTab, setActiveTab] = useState(0);
    const [editedInstructor, setEditInstructor] = useState({});
    const [editLocation, setEditLocation] = useState([]);
    const [instructor, updateInstructor] = useInstructor(instructorId)
    const [
        publishedInstructor,
        updatePublishedInstructor,
        deletePublishedInstructor
    ] = usePublishedInstructor(instructorId);

    const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

    useEffect(() => {
        if(instructor) {
            setEditInstructor(instructor);
        }
    }, [JSON.stringify(instructor)])

    useEffect(() => {
        if(instructorId && publishedInstructor?.lat && publishedInstructor?.lon) {
            setEditLocation([publishedInstructor.lat, publishedInstructor.lon])
        } else {
            setEditLocation([]);
        }
    }, [JSON.stringify(publishedInstructor), instructorId])


    const save = () => {
        updateInstructor(instructorId, editedInstructor)
            .then(() => handleClose(true))
            .catch(error => console.error('error saving...'))
    }

    const deleteHandler = () => {
        const markedAsDeleted = {
            ...editedInstructor,
            deleted: true
        }

        updateInstructor(instructorId, markedAsDeleted)
            .then(() => handleClose(true))
            .catch(error => console.error('error deleting...'))

        deletePublishedInstructor(instructorId);
    }

    const saveAndPublish = () => {
        updateInstructor(instructorId, {...editedInstructor, published: true})
            .then(() => {
                updatePublishedInstructor(instructorId, {
                    ...editedInstructor,
                    lat: editLocation[0],
                    lon: editLocation[1],
                    owner: editedInstructor.owner ? editedInstructor.owner : '',
                })
                    .then(() => handleClose(true))
                    .catch(() => console.error('error publishing...'))
            })
            .catch(() => console.error('error saving...'))
    }

    const getInstructorInfo = () => {
        if((!isMobile || 0 === activeTab) && Object.keys(instructor).length > 0) {
            return (
                <Grid item={true} sm={4} xs={12}>
                    <form noValidate autoComplete="off" onSubmit={save}>
                        <TextField
                            id="name"
                            label="Navn"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.name}
                            onChange={event => setEditInstructor(ei => ({...ei, name: event.target.value}))}
                        />
                        <TextField
                            id="title"
                            label="Tittel"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.title}
                            onChange={event => setEditInstructor(ei => ({...ei, title: event.target.value}))}
                        />
                        <TextField
                            id="description"
                            label="Info"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.description}
                            onChange={event => setEditInstructor(ei => ({...ei, description: event.target.value}))}
                        />
                        <TextField
                            id="email"
                            label="Epost"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.email}
                            onChange={event => setEditInstructor(ei => ({...ei, email: event.target.value}))}
                        />
                        <TextField
                            id="phone"
                            label="Telefon"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.phone}
                            onChange={event => setEditInstructor(ei => ({...ei, phone: event.target.value}))}
                        />
                        <TextField
                            id="address"
                            label="Adresse"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.address}
                            onChange={event => setEditInstructor(ei => ({...ei, address: event.target.value}))}
                        />
                        <TextField
                            id="place"
                            label="Sted"
                            variant="filled"
                            fullWidth={true}
                            sx={{
                                mb: 2
                            }}
                            value={editedInstructor.place}
                            onChange={event => setEditInstructor(ei => ({...ei, place: event.target.value}))}
                        />
                        <FormControl
                            variant={"filled"}
                            sx={{
                                mb: 2
                            }}
                            fullWidth={true}
                        >
                            <InputLabel id={"county-select"}>
                                Fylke
                            </InputLabel>
                            <Select
                                labelId={"county-select"}
                                id={"county"}
                                value={editedInstructor.county || ""}
                                onChange={event => setEditInstructor(ei => ({...ei, county: event.target.value}))}
                            >
                                {counties.map(county => (
                                    <MenuItem
                                        value={county}
                                        key={county}>
                                        {county}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </form>
                </Grid>
            )
        } else {
            return null;
        }
    }

    const getInstructorMap = () => {
        if(!isMobile || 1 === activeTab) {
            return (
                <Grid item sm={8} xs={12}>
                    <Box
                        sx={{
                            width: "100%",
                            height: "500px"
                        }}
                    >
                        <EditInstructorMap
                            initialInput={editedInstructor.address || editedInstructor.place}
                            onMarkerSet={location => setEditLocation(location)}
                            existingLocation={editLocation}
                        />
                    </Box>
                </Grid>
            )
        } else {
            return null;
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}
            sx={{
                padding: 2
            }}
        >
            <DialogTitle>
                Rediger instruktør
            </DialogTitle>
            <DialogContent>
                <WithLoadingProgress loading={Object.keys(instructor).length == 0 && instructorId}>
                    <Hidden smUp={true}>
                        <Tabs
                            sx={{
                                mb: 2
                            }}
                            value={activeTab}
                            indicatorColor={"primary"}
                            textColor={"primary"}
                            onChange={(event, value) => setActiveTab(value)}
                        >
                            <Tab label="Kursholder" />
                            <Tab label="Kartplassering" />
                        </Tabs>
                    </Hidden>
                    <Grid spacing={2} container={true}>
                        {getInstructorInfo()}
                        {getInstructorMap()}
                    </Grid>
                </WithLoadingProgress>
            </DialogContent>
            <DialogActions
                sx={{
                    padding: 2
                }}
            >
                <Button onClick={handleClose}>
                    Avbryt
                </Button>
                <Button
                    onClick={deleteHandler}
                    variant={"contained"}
                    color={"secondary"}
                    sx={{
                        ml: 2
                    }}
                >
                    Slett
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{
                        ml: 2
                    }}
                    onClick={save}
                >
                    Lagre
                </Button>
                <Box flexGrow={1}/>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={saveAndPublish}
                >
                    Lagre og publiser
                </Button>
            </DialogActions>
        </Dialog>
    )
}
