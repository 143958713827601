import {useFolderState} from "./folderContext";
import {collection, doc, setDoc} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";

export const useCreateFolder = ({folder=null}) => {
    const {
        parentFolders
    } = useFolderState()
    const {db} = useFirebaseState()
    const folderIdsParams = [...parentFolders, folder]
        .filter(f => f !== null)
        .map(f => [f.id, 'folders']).flat(1)

    const create = newFolder => {
        const foldersRef = collection(db, 'folders', ...folderIdsParams);
        return setDoc(
            doc(foldersRef),
            newFolder
        )
    }

    return {
        create
    }
}