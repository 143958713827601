import {Stack} from "@mui/material";
import {Form} from "../form/Form";
import React, {useEffect} from "react";
import {TextFieldForm} from "../form/TextFieldForm";
import {FORM_INITIALIZED, useFormDispatch, useFormState} from "../form/formContext";

export const FolderForm = ({folderIsFile=false, disabled=false}) => {
    const formDispatch = useFormDispatch()
    const {initialized} = useFormState()

    useEffect(
        () => {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    title: "",
                }
            })
        },[formDispatch]
    )


    if(!initialized) {
        return null
    }


    return (
        <Form>
            <Stack
                direction="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                sx={{
                    mt: 2
                }}
            >
                <TextFieldForm
                    formKey={'title'}
                    label={`Navn på ${folderIsFile ? 'filen' : 'mappen'}`}
                    ariaLabel={`Skriv inn navnet på ${folderIsFile ? "filen" : "mappen"}`}
                    fullWidth={true}
                    autoFocus={true}
                    disabled={Boolean(disabled)}
                />
            </Stack>
        </Form>
    )
}