import React from 'react'
import {Alert, Avatar, Button, Chip, Container, Grid, Paper, Snackbar, Stack, Typography} from "@mui/material";
import {useFirebaseState} from "../firebase/firebaseContext";
import {useNavigate} from "react-router";
import {Edit, ExitToApp, PriorityHigh} from "@mui/icons-material";
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import {sendEmailVerification} from "firebase/auth";
import {EditProfile} from "./EditProfile";
import {signOut} from "firebase/auth";

export const Me = () => {
    const {auth} = useFirebaseState()
    const navigate = useNavigate()
    const isAdmin = useCheckIfAdmin();
    const [sendVerificationEmail, setSendVerificationEmail] = React.useState(false)
    const [verificationEmailSent, setVerificationEmailSent] = React.useState(false)
    const [editProfile, setEditProfile] = React.useState(false)

    const handleSignOut = () => {
        signOut(auth)
            .then(() => {
                navigate('/login')
            })
            .catch(error => console.error({error}))

    }

    const verifyEmailOrEditProfile = user => {
        if(!user.emailVerified) {
            return (
                <Button
                    variant={"outlined"}
                    startIcon={<PriorityHigh />}
                    disabled={verificationEmailSent}
                    onClick={() => {
                        sendEmailVerification(auth.currentUser)
                            .then(() => {
                                console.debug("Email verification sent")
                                setSendVerificationEmail(true)
                                setVerificationEmailSent(true)
                            })
                            .catch(error => {
                                setSendVerificationEmail(true)
                                console.error("Email verification failed")
                                console.error(error)
                            })

                    }}
                >
                    Verifiser epost
                </Button>
            )
        } else {
            return (
                <Button
                    variant={"outlined"}
                    startIcon={<Edit />}
                    onClick={() => setEditProfile(true)}
                >
                    Endre profil
                </Button>
            )
        }
    }

    const permissionChip = () =>{
        if(isAdmin) {
            return (
                <Chip label="Admin" color="primary" />
            )
        } else {
            return null
        }
    }

    if(!auth || !auth.currentUser) {
        navigate('/login')
    } else {
        const user = auth.currentUser

        return (
            <Paper
                sx={{
                    padding: 4,
                    mb: 4
                }}
            >
                <Stack
                    direction={"row"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                    spacing={2}
                >
                    <Typography variant="h4">
                        Velkommen {user.displayName}
                    </Typography>
                    {permissionChip()}
                </Stack>
                <Grid
                    container={true}
                    spacing={2}
                    sx={{
                        mt: 2,
                        mb: 2
                    }}
                >
                    <Grid
                        item={true}
                        xs={3}
                    >
                        <Avatar
                            src={user.photoURL}
                            alt={user.displayName}
                            sx={{
                                width: 100,
                                height: 100
                            }}
                        />
                    </Grid>
                    <Grid
                        item={true}
                        xs={9}
                    >
                        <Stack
                            spacing={1}
                            alignItems={"flex-start"}
                        >

                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <Typography>
                                    Epost: {user.email}
                                </Typography>
                            </Stack>
                            <Stack
                                direction={"row"}
                                alignItems={"center"}
                            >
                                <Typography>
                                    Navn: {user.displayName}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack
                    direction={"row"}
                    spacing={2}
                >
                    <Button
                        variant={"outlined"}
                        onClick={handleSignOut}
                        startIcon={<ExitToApp />}
                    >
                        Logg ut
                    </Button>
                    {verifyEmailOrEditProfile(user)}
                </Stack>
                <Snackbar
                    open={sendVerificationEmail}
                    autoHideDuration={6000}
                    onClose={() => setSendVerificationEmail(false)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Alert
                        onClose={() => setSendVerificationEmail(false)}
                        severity="success"
                        sx={{ width: '100%' }}
                        variant={'filled'}
                    >
                        Epost med verifiseringskode er sendt til {user.email}
                    </Alert>
                </Snackbar>
                <EditProfile
                    open={editProfile}
                    onClose={() => setEditProfile(false)}
                />
            </Paper>
        )
    }
}