import {collection, getDocs, query, where} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";

export const useFileExists = ({folderIds=[]}) => {
    const {db} = useFirebaseState();

    const folderIdsParams = folderIds.map(folder => [folder, 'folders']).flat(1)

    const fileExists = fileName => {
        const foldersRef = collection(db, 'folders', ...folderIdsParams);
        const q = query(foldersRef, where('fileName', '==', fileName));
        return getDocs(q)
            .then(querySnapshot => {
                if(querySnapshot.size > 0) {
                    return Promise.reject()
                } else {
                    return Promise.resolve()
                }
            })
    }

    return fileExists

}