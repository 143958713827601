import React from 'react';
import {Avatar, IconButton} from "@mui/material";
import {useNavigate} from "react-router";

export const UserAvatar = ({user}) => {
    const navigate = useNavigate()

    const handleClick = () => {
        navigate('/me')
    };

    return (
        <div>
            <IconButton onClick={handleClick} size="large">
                <Avatar alt={user.displayName} src={user.photoURL} />
            </IconButton>
        </div>
    );
}