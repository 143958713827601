import Button from "@mui/material/Button";
import React from "react";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";
import {useFirebaseState} from "../firebase/firebaseContext";
import {
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Paper,
    Stack,
    Typography
} from "@mui/material";
import {LoginUsernamePassword} from "./LoginUsernamePassword";
import {SignupUsernamePassword} from "./SignupUsernamePassword";
import {useNavigate} from "react-router";
import {useDeepCompareEffectNoCheck} from "use-deep-compare-effect";
import {ForgotPassword} from "./ForgotPassword";

export const Login = () => {
    const {auth} = useFirebaseState()
    const [open, setOpen] = React.useState(false)
    const [forgotPasswordOpen, setForgotPasswordOpen] = React.useState(false)
    const provider = new GoogleAuthProvider()
    const navigate = useNavigate()

    const handleGoogleLogin = () => {
        signInWithPopup(auth, provider)
            .then(() => {
                console.debug('logged in ')
            })
    }

    useDeepCompareEffectNoCheck(
        () => {
            if (auth && auth.currentUser) {
                navigate('/me')
            }
        }, [auth]
    )

    if(auth && auth.currentUser) {
        navigate('/me')
    }

    return (
        <Container
            maxWidth={"md"}
        >
            <Paper
                sx={{
                    padding: 2
                }}
            >
                <Typography variant={"h4"}>
                    Logg inn
                </Typography>
                <LoginUsernamePassword />
                <Divider
                    sx={{
                        mt: 2,
                        mb: 2
                    }}

                >
                    eller
                </Divider>
                <Stack
                    alignItems={"center"}
                >
                    <Button>
                        <img
                            src={"/btn_google_signin_dark_normal_web.png"}
                            onClick={handleGoogleLogin}
                        />
                    </Button>
                </Stack>
                <Divider
                    sx={{
                        mt: 2,
                        mb: 2
                    }}

                >
                    eller
                </Divider>
                <Stack
                    alignItems={"center"}
                    justifyContent={"center"}
                    direction={"row"}
                    spacing={2}
                >
                    <Button
                        variant={"contained"}
                        onClick={() => setOpen(true)}
                    >
                        Opprett ny bruker
                    </Button>
                    <Button
                        variant={"text"}
                        onClick={() => setForgotPasswordOpen(true)}
                    >
                        Glemt passordet?
                    </Button>
                </Stack>
            </Paper>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={open}
                onClose={() => setOpen(false)}
            >
                <DialogTitle>
                    Opprett ny bruker
                </DialogTitle>
                <DialogContent>
                    <SignupUsernamePassword />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setOpen(false)}
                    >
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                maxWidth={"md"}
                fullWidth={true}
                open={forgotPasswordOpen}
                onClose={() => setForgotPasswordOpen(false)}
            >
                <DialogTitle>
                    Glemt passordet?
                </DialogTitle>
                <DialogContent>
                    <ForgotPassword
                        onClose={() => setForgotPasswordOpen(false)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setForgotPasswordOpen(false)}
                    >
                        Lukk
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}