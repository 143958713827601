import {useFirebaseState} from "../firebase/firebaseContext";
import {doc, setDoc} from "firebase/firestore";

export const useDeleteUser = () => {
    const {db} = useFirebaseState()

    const deleteUser = ({user}) => {
        return setDoc(
            doc(db, 'users', user.owner),
            {
                deleted: true
            },
            {
                merge: true
            }
        )
    }

    return deleteUser
}