import {getDownloadURL, getStorage, ref} from "firebase/storage";

export const useFileContent = ({url, filename}) => {
    const storage = getStorage();

    const downloadFile = () => {
        const fileRef = ref(storage, url)
        getDownloadURL(fileRef)
            .then(url => {
                const xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = (event) => {
                    const blobUrl = window.URL.createObjectURL(new Blob([xhr.response]))
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                };
                xhr.open('GET', url);
                xhr.send();
            })
    }

    return {
        downloadFile
    }
}