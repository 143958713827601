import {getStorage, ref, uploadBytesResumable} from 'firebase/storage'
import {REFRESH_FORCED, useFolderDispatch, useFolderState} from "../folder/folderContext";
import {FILE_ADDED, FILE_ERROR, FILE_PROGRESS_UPDATED, FILE_REMOVED, useFileDispatch} from "../file/fileContext";
import {useCreateFolder} from "../folder/useCreateFolder";
import {useFileExists} from "../folder/useFileExists";

export const useFirebaseFileUpload = ({folder=null}) => {
    const fileDispatch = useFileDispatch()
    const {
        parentFolders,
    } = useFolderState()
    const storage = getStorage()
    const {create} = useCreateFolder({
        folder: folder
    })
    const folderDispatch = useFolderDispatch()
    const parentFolderIds = [
        ...parentFolders,
        folder
    ].filter(f => f !== null).map(f => f.id)

    const fileExists = useFileExists({
        folderIds: parentFolderIds
    })

    const parentFoldersPath = () => {
        return [...parentFolders, folder]
            .filter(f => f !== null)
            .map(f => f.title).join('/')
    }

    const uploadFile = file => {
        fileDispatch({
            type: FILE_ADDED,
            file: {
                id: `${file.name}-${file.size}`,
                filename: file.name,
                progress: 0
            }
        })

        fileExists(file.name)
            .then(() => {
                const path = `/files/${parentFoldersPath()}/${file.name}`
                console.debug({path})
                const fileRef = ref(storage, path)
                uploadBytesResumable(fileRef, file)
                    .on(
                        'state_changed',
                        snapshot => {
                            fileDispatch({
                                type: FILE_PROGRESS_UPDATED,
                                file: {
                                    id: `${file.name}-${file.size}`,
                                    filename: file.name,
                                    progress: (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                                }
                            })
                        },
                        error => {
                            console.error(error)
                        },
                        () => {
                            const timestamp = new Date();
                            create({
                                title: file.name,
                                fileName: file.name,
                                fileSize: file.size,
                                createdAt: timestamp,
                                type: file.type,
                                modifiedAt: timestamp,
                                url: path
                            })
                                .then(() => {
                                    folderDispatch({type: REFRESH_FORCED})
                                    fileDispatch({
                                        type: FILE_REMOVED,
                                        file: {
                                            id: `${file.name}-${file.size}`,
                                        }
                                    })
                                })
                                .catch(error => console.error(error))

                        }
                    )
            })
            .catch(() => {
                fileDispatch({
                    type: FILE_ERROR,
                    file: {
                        id: `${file.name}-${file.size}`,
                        filename: file.name,
                        progress: 0
                    },
                    error: "Filen finnes allerede"
                })
            })
    }

    return {
        uploadFile
    }
}