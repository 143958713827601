import {useFirebaseState} from "./firebaseContext";
import {useEffect, useState} from "react";
import {collection, doc, getDoc, setDoc, updateDoc} from "firebase/firestore";

export const useInstructor = id => {
    const [instructor, setInstructor] = useState({});
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db && id) {
            const instructorsRef = doc(db, 'instructors', id);
            getDoc(instructorsRef)
                .then(doc => setInstructor(doc.exists ? doc.data() : {}))

        } else {
            setInstructor({});
        }
    }, [db, id, setInstructor])

    const updateInstructor = (id, updatedInstructor) => {
        if(db && id) {
            const instructorsRef = doc(db, 'instructors', id);
            return updateDoc(
                instructorsRef,
                updatedInstructor
            )
        } else if(db) {
            const instructorsRef = collection(db, 'instructors');
            return setDoc(
                doc(instructorsRef),
                updatedInstructor
            )
        }
    }

    return [instructor, updateInstructor];
}