import {useMemo} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {Box} from "@mui/material";
import {EDIT_USER_OPENED, useUserDispatch} from "./userContext";
import {MRT_Localization_NO} from 'material-react-table/locales/no'

export const UsersTable = ({data}) => {
    const userDispatch = useUserDispatch()

    const editHandler = row => {
        userDispatch({
            type: EDIT_USER_OPENED,
            user: row.original
        })
    }

    const columns = useMemo(
        () => [
            {
                accessorKey: 'name',
                header: 'Navn',
                size: 150,
            },
            {
                accessorKey: 'email',
                header: 'Epost',
                size: 150,
            },
            {
                accessorKey: 'role',
                header: 'Rolle',
                size: 100,
            },
            {
                accessorKey: 'emailVerified',
                header: 'Epost verifisert',
                size: 50,
                Cell: ({renderedCellValue}) => (
                    renderedCellValue ? "Ja" : "Nei"
                )
            }
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        muiTableBodyRowProps: ({ row }) => ({
            onClick: () => {
                editHandler(row)
            },
            sx: {
                cursor: 'pointer', //you might want to change the cursor too when adding an onClick
            },
        }),
    });

    return (
        <Box
            sx={{
                maxWidth: {
                    xs: "100dvw",
                    md: "75dvw"
                }
            }}
        >
            <MaterialReactTable
                table={table}
                localization={MRT_Localization_NO}
            />
        </Box>
    )
}