import React from 'react';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material/styles';
import {green, grey, indigo} from "@mui/material/colors";
import {nbNO} from '@mui/material/locale'

const theme = createTheme(
    {
        palette: {
            primary: indigo,
            secondary: green,
            background: {
                default: grey[100]
            }
        },
    },
    nbNO
);

export const MyTheme = ({children}) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}