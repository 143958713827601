import React, {createContext, useContext, useReducer} from "react";
export const RENDERED = 'header/rendered';
export const MENU_OPENED = 'header/menuOpened';
export const MENU_CLOSED = 'header/menuClosed';

const HeaderStateContext = createContext(undefined);
const HeaderDispatchContext = createContext(undefined);

const initialState = {
    rendered: false,
    menuOpen: false
}

const headerReducer = (state, action) => {
    switch (action.type) {
        case RENDERED:
            return {
                ...state,
                rendered: true
            }
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true
            }
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const HeaderProvider = ({children}) => {
    const [state, dispatch] = useReducer(headerReducer, initialState, undefined);

    return (
        <HeaderStateContext.Provider value={state}>
            <HeaderDispatchContext.Provider value={dispatch}>
                {children}
            </HeaderDispatchContext.Provider>
        </HeaderStateContext.Provider>
    )
}

export const useHeaderState = () => {
    const context = useContext(HeaderStateContext);
    if(undefined === context) {
        throw new Error('useHeaderState must be used within a HeaderProvider');
    } else {
        return context;
    }
}

export const useHeaderDispatch = () => {
    const context = useContext(HeaderDispatchContext);
    if(undefined === context) {
        throw new Error('useHeaderDispatch must be used within a HeaderProvider');
    } else {
        return context;
    }
}
