import {
    Box,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Stack,
    Typography
} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import TextField from "@mui/material/TextField";
import RichTextEditor from "react-rte";
import Button from "@mui/material/Button";
import {useArticle} from "../firebase/useArticle";
import {useDropzone} from "react-dropzone";
import {useFirebaseNewsUpload} from "./useFirebaseNewsUpload";


export const EditArticle = ({open, articleId, handleClose}) => {
    const {article, updateArticle} = useArticle(articleId)
    const [editArticle, setEditArticle] = useState(article)
    const {
        uploadFile,
        uploadProgress,
    } = useFirebaseNewsUpload()


    const onDrop = useCallback(
        (acceptedFiles) => {
            uploadFile({
                file: acceptedFiles[0],
                callback: url => {
                    setEditArticle(ea => ({
                        ...ea,
                        media: url
                    }))
                }
            })
        },
        []
    )

    const {
        getRootProps,
        getInputProps,
        open: openHandler
    } = useDropzone({
        onDrop: onDrop,
        multiple: false
    })

    useEffect(
        () => {
            if(article) {
                setEditArticle({
                    ...article,
                    text: RichTextEditor.createValueFromString(article.text, 'html')
                })
            }
        },
        [JSON.stringify(article)]
    )

    const save = () => {
        updateArticle(
            articleId,
            {
                ...editArticle,
                text: editArticle.text.toString('html')
            }
            )
            .then(() => handleClose(true))
            .catch(error => console.error('error saving...'))
    }

    const media = () => {
        if (uploadProgress < 101 && uploadProgress > 0) {
          return (
              <CircularProgress variant="determinate" value={uploadProgress} />
          )
        } else if(editArticle.media) {
            return (
                <img
                    src={editArticle.media}
                    alt={editArticle.title}
                    width={400}
                />
            )
        } else {
            return (
                <Typography
                    variant="h6"
                >
                    Last opp bilde
                </Typography>
            )
        }
    }

    const getContent = () => {
        if(editArticle) {
            return (
                <form noValidate autoComplete="off" onSubmit={save}>
                    <TextField
                        id="tittel"
                        label="Tittel"
                        variant="outlined"
                        fullWidth={true}
                        value={editArticle.title}
                        sx={{
                            mt: 2,
                            mb: 2
                        }}

                        onChange={event => setEditArticle(ea => ({...ea, title: event.target.value}))}
                    />
                    <Grid
                        container={true}
                        spacing={2}
                    >
                        <Grid
                            item={true}
                            xs={8}
                        >
                            <RichTextEditor
                                value={editArticle.text}
                                onChange={data => setEditArticle(ea => ({...ea, text: data}))}
                            />
                        </Grid>
                        <Grid
                            item={true}
                            xs={4}
                        >
                            <Stack
                                spacing={2}
                                alignItems={"flex-start"}
                            >
                                <Box
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    {media()}
                                </Box>
                                <Stack
                                    direction={"row"}
                                    spacing={2}
                                >
                                    <Button
                                        variant={'outlined'}
                                        disabled={!editArticle.media}
                                        onClick={() => {
                                            setEditArticle(ea => ({...ea, media: null}))
                                        }}
                                    >
                                        Fjern bilde
                                    </Button>
                                    <Button
                                        variant={"outlined"}
                                        onClick={openHandler}
                                    >
                                        Velg bilde
                                    </Button>
                                </Stack>
                            </Stack>
                        </Grid>

                    </Grid>
                </form>
            )
        } else {
            return null
        }
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={"xl"}
            fullWidth={true}
        >
            <DialogTitle>
                {articleId ? "Rediger nyhetsartikkel" : "Opprett nyhetsartikkel"}
            </DialogTitle>
            <DialogContent>
                {getContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color={"primary"}>
                    Avbryt
                </Button>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    onClick={save}
                >
                    Lagre
                </Button>
            </DialogActions>
        </Dialog>
    )
}