import React, {useEffect} from 'react';
import {initializeApp} from "firebase/app"
import {getAuth, onAuthStateChanged} from 'firebase/auth'
import {getFirestore} from "firebase/firestore"
import {INITIALIZED, useFirebaseDispatch, USER_LOGGED_IN, USER_LOGGED_OUT} from "./firebaseContext";
import {useCurrentUser} from "./useCurrentUser";

const { initializeAppCheck, ReCaptchaV3Provider } = require("firebase/app-check")

const firebaseConfig = {
    apiKey: "AIzaSyBuQnfedHHheFo1t7_PAdGU3zmOZf1GUUY",
    authDomain: "bekymringsmestring.firebaseapp.com",
    databaseURL: "https://bekymringsmestring.firebaseio.com",
    projectId: "bekymringsmestring",
    storageBucket: "bekymringsmestring.appspot.com",
    messagingSenderId: "127875457311",
    appId: "1:127875457311:web:1388ea9e36ff79ff9b902a",
    measurementId: "G-6PCSX5762K"
};

export const Firebase = ({children}) => {
    const dispatch = useFirebaseDispatch();
    const {updateUser} = useCurrentUser()

    useEffect(() => {
        const app = initializeApp(firebaseConfig);
        window.FIREBASE_APPCHECK_DEBUG_TOKEN="development" ===process.env.NODE_ENV
        initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider('6LcAQaEfAAAAAK63WgldHVaaNDxOQZhiMj8_xh81'),
            isTokenAutoRefreshEnabled: true
        });
        const auth = getAuth(app)
        const db = getFirestore(app)

        dispatch({
            type: INITIALIZED,
            db: db,
            auth: auth
        })

        onAuthStateChanged(
            auth,
            user => {
                if (user) {
                    user.getIdTokenResult()
                        .then(idTokenResult => {
                            updateUser({
                                db: db,
                                userNow: {
                                    owner: user.uid,
                                    name: user.displayName || user.email,
                                    email: user.email,
                                    photoURL: user.photoURL,
                                    emailVerified: user.emailVerified,
                                }
                            })
                            dispatch({
                                type: USER_LOGGED_IN,
                                displayName: auth.currentUser.displayName,
                                uid: auth.currentUser.uid,
                                admin: !!idTokenResult.claims.admin,
                                instructor: !!idTokenResult.claims.instructor
                            })
                        })
                } else {
                    dispatch({
                        type: USER_LOGGED_OUT,
                    })
                }
            }
        )
    }, [])

    return (
        <>
            {children}
        </>
    )
}