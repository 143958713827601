import {useEffect, useState} from "react";
import {useFirebaseState} from "./firebaseContext";
import {doc, getDoc, updateDoc} from "firebase/firestore";

export const useForInstructorsContent = ({forceRefresh=0}) => {
    const [instructorsContent, setForInstructorsContent] = useState('');
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const forInstructorsContentRef = doc(db, 'content', 'forInstructors')
            getDoc(forInstructorsContentRef)
                .then(doc => {
                    if(doc.exists) {
                        setForInstructorsContent(doc.data().content)
                    }
                })

        }
    }, [db, setForInstructorsContent, forceRefresh])

    const updateForInstructorsContent = content => {
        if(db) {
            const forInstructorsContentRef = doc(db, 'content', 'forInstructors')
            return updateDoc(
                forInstructorsContentRef,
                {
                    content
                }
            )
        }
    }

    return [instructorsContent, updateForInstructorsContent];
}