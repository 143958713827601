import {FORM_INITIALIZED, useFormDispatch} from "./formContext";
import useDeepCompareEffect from "use-deep-compare-effect";

export const InitializeForm = ({initialValues}) => {
    const formDispatch  = useFormDispatch()

    useDeepCompareEffect(() => {
        if(initialValues && Object.keys(initialValues).length > 0) {
            formDispatch({
                type: FORM_INITIALIZED,
                initialFormData: {
                    ...initialValues,
                    name: initialValues.name ? initialValues.name : "",
                    description: initialValues.description ? initialValues.description : "",
                    email: initialValues.email ? initialValues.email : "",
                    phone: initialValues.phone ? initialValues.phone : "",
                    address: initialValues.address ? initialValues.address : "",
                    place: initialValues.place ? initialValues.place : "",
                    county: initialValues.county ? initialValues.county : ""
                }
            })
        }
    }, [initialValues, formDispatch])


    return null
}