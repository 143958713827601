import React, {createContext} from 'react'

export const REFRESH_FORCED = 'REFRESH_FORCED'
export const USERS_CLEARED = 'USERS_CLEARED'
export const USERS_RECEIVED = 'USERS_RECEIVED'
export const ROLE_CHECKED = 'ROLE_CHECKED'
export const ROLE_UNCHECKED = 'ROLE_UNCHECKED'
export const EMAIL_VERIFIED_CHECKED = 'EMAIL_VERIFIED_CHECKED'
export const EMAIL_VERIFIED_UNCHECKED = 'EMAIL_VERIFIED_UNCHECKED'
export const NAME_FILTERED = 'NAME_FILTERED'
export const EDIT_USER_OPENED = 'EDIT_USER_OPENED'
export const EDIT_USER_CLOSED = 'EDIT_USER_CLOSED'

const UserStateContext = createContext(undefined)
const UserDispatchContext = createContext(undefined)

const initialState = {
    forceRefresh: 0,
    users: [],
    roleFilter: [
        'Admin',
        'Instructor',
        'User'
    ],
    emailVerifiedFilter: true,
    nameFilter: null,
    editUser: null
}

const userReducer = (state, action) => {
    switch(action.type) {
        case REFRESH_FORCED:
            return {
                ...state,
                forceRefresh: state.forceRefresh + 1
            }
        case USERS_RECEIVED:
            return {
                ...state,
                users: [...state.users, ...action.users]
            }
        case ROLE_CHECKED:
            return {
                ...state,
                roleFilter: [...state.roleFilter, action.role],
                users: []
            }
        case ROLE_UNCHECKED:
            return {
                ...state,
                roleFilter: state.roleFilter.filter(role => role !== action.role),
                users: []
            }
        case EMAIL_VERIFIED_CHECKED:
            return {
                ...state,
                emailVerifiedFilter: true,
                users: []
            }
        case EMAIL_VERIFIED_UNCHECKED:
            return {
                ...state,
                emailVerifiedFilter: false,
                users: []
            }
        case USERS_CLEARED:
            return {
                ...state,
                users: []
            }
        case NAME_FILTERED:
            return {
                ...state,
                nameFilter: action.name ? action.name : null,
                users: []
            }
        case EDIT_USER_OPENED:
            return {
                ...state,
                editUser: action.user
            }
        case EDIT_USER_CLOSED:
            return {
                ...state,
                forceRefresh: state.forceRefresh + 1,
                editUser: null
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const UserProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(userReducer, initialState, undefined)
    return (
        <UserStateContext.Provider value={state}>
            <UserDispatchContext.Provider value={dispatch}>
                {children}
            </UserDispatchContext.Provider>
        </UserStateContext.Provider>
    )
}

export const useUserState = () => {
    const context = React.useContext(UserStateContext)
    if (context === undefined) {
        throw new Error('useUserState must be used within a UserProvider')
    } else {
        return context
    }
}

export const useUserDispatch = () => {
    const context = React.useContext(UserDispatchContext)
    if (context === undefined) {
        throw new Error('useUserDispatch must be used within a UserProvider')
    } else {
        return context
    }
}