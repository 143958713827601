import React from 'react';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import {createRoot} from 'react-dom/client'
import {MyTheme} from "./app/MyTheme";

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
    <MyTheme>
        <App />
    </MyTheme>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
