import React from "react";
import {Typography} from "@mui/material";
import {FormikTextField} from "../form/FormikTextField";


export const NumberOfBooksStep = () => {

    return (
        <>
            <Typography
                sx={{
                    mb: 2
                }}
                variant={"body2"}
                gutterBottom={true}
            >
                Bøkene sendes direkte til deg fra lageret hvor de ligger pakket.
                Hvor mange bøker ønsker du å bestille?
            </Typography>
            <FormikTextField
                formikKey={"numberOfBooks"}
                label={"Antall bøker"}
                sx={{
                    mb: 2
                }}
                type={"number"}
                min={"1"}
            />
            <Typography
                variant={"body2"}
                gutterBottom={true}
            >
                Bøkene koster 490 kr per stk, og det kommer et tillegg på 990 kr i porto og ekspedisjonsgebyr. Det lønner seg å bestille flere bøker om gangen for å holde portokostnadene lavest mulig. Prisene for bøker og porto justeres 01.01 hvert år i tråd med den generelle prisutviklingen i samfunnet.
            </Typography>
        </>
    )
}