import {Button, List, ListItem, ListItemButton, ListItemText, Stack, Typography} from "@mui/material";
import {useUnclaimedInstructors} from "./useUnclaimedInstructors";
import {useEffect, useState} from "react";
import {useGrantInstructor} from "./useGrantInstructor";

export const ConnectInstructor = ({user, open, onClose}) => {
    const [selected, setSelected] = useState(null)
    const {
        grantAndConnect,
        refresh
    } = useGrantInstructor()

    const instructors = useUnclaimedInstructors({
        ready: open
    })

    const selectedInstructor = () => {
        if(selected) {
            return (
                <Typography>
                    Valgt: {selected.name}
                </Typography>
            )
        } else {
            return null
        }
    }

    const connectHandler = () => {
        grantAndConnect({
            user: user,
            instructorId: selected.id
        })
    }

    useEffect(
        () => {
            if(refresh > 0) {
                onClose()
            }
        }, [
            refresh
        ]
    )

    if(open) {
        return (
            <Stack
                alignItems={"center"}
                justifyContent={"flex-start"}
                spacing={2}
            >
                {selectedInstructor()}
                <Button
                    variant="contained"
                    disabled={!selected}
                    onClick={connectHandler}
                >
                    Koble sammen
                </Button>
                <List
                    sx={{
                        overflowY: "auto",
                        minWidth: "400px",
                        backgroundColor: "white"
                    }}
                >
                    {instructors.map(instructor => (
                        <ListItemButton
                            key={instructor.id}
                            onClick={() => setSelected(instructor)}
                            selected={selected?.id === instructor.id}
                        >
                            <ListItem>
                                <ListItemText
                                    primary={instructor.name}
                                    secondary={instructor.email}
                                />
                            </ListItem>
                        </ListItemButton>
                    ))}
                </List>
            </Stack>
        )
    } else {
        return null
    }
}