import {useEffect, useState} from "react";
import {useFirebaseState} from "./firebaseContext";
import {doc, getDoc, updateDoc} from "firebase/firestore";

export const useMeditationContent = ({forceRefresh=0}) => {
    const [meditationContent, setMedidationContent] = useState('');
    const {db} = useFirebaseState();

    useEffect(() => {
        if(db) {
            const medidationContentRef = doc(db, 'content', 'meditation');
            getDoc(medidationContentRef)
                .then(doc => {
                    if(doc.exists) {
                        setMedidationContent(doc.data().content)
                    }
                })

        }
    }, [db, setMedidationContent, forceRefresh])

    const updateMedidationContent = content => {
        if(db) {
            const medidationContentRef = doc(db, 'content', 'meditation');
            return updateDoc(
                medidationContentRef,
                {
                    content: content
                }
            )
        }
    }

    return [meditationContent, updateMedidationContent];
}