import React from 'react';
import {useCheckIfAdmin} from "../firebase/useCheckIfAdmin";
import Paper from "@mui/material/Paper";
import {EmailContent} from "./EmailContent";
import {Typography} from "@mui/material";


export const Email = () => {
    const isAdmin = useCheckIfAdmin();

    if(!isAdmin) {
        return null;
    }

    return (
        <div>
            <Paper
                sx={{
                    p: 8,
                    mb: 4
                }}
            >
                <Typography variant={"h2"}>
                    Send epost
                </Typography>
                <Typography>
                    Her kan du åpne epostklienten, hvor alle kursholdere med registrert epostadresse blir lagt til som BCC.
                </Typography>
                <EmailContent />
            </Paper>
        </div>
    )
}