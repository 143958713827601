import Toolbar from "@mui/material/Toolbar";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import {AdminMap} from "../admin/AdminMap";
import React, {useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {FORM_VALUE_UPDATED, useFormDispatch, useFormState} from "../form/formContext";
import {Stack, Typography} from "@mui/material";

const useStyles = makeStyles(theme => ({
    mapContainer: {
        height: 400
    }
}))

export const InstructorMap = ({formKey}) => {
    const [searchInput, setSearchInput] = useState("")
    const [position, setPosition] = useState([])
    const [zoom, setZoom] = useState(4)
    const classes = useStyles()
    const {
        formData,
    } = useFormState()
    const formDispatch = useFormDispatch()

    const handleSearch = query => {
        if(!query) {
            return
        }
        const params = {
            q: query,
            limit: 1,
            format: "json"
        }
        const url = 'https://nominatim.openstreetmap.org/search?'
        const queryParams = Object.keys(params).map(key => `${key}=${params[key]}`).join('&')
        fetch(`${url}${queryParams}`)
            .then(response => response.json())
            .then(json => {
                if(json.length > 0) {
                    setPosition([json[0].lat, json[0].lon])
                    setZoom(12)
                }
            })
    }

    const getMarker = () => {
        if(formData[formKey]?.lat && formData[formKey]?.lon) {
            return (
                <Marker position={[formData[formKey].lat, formData[formKey].lon]}/>
            )
        }
        return null
    }

    const onMarkerSet = value => {
        formDispatch({
            type: FORM_VALUE_UPDATED,
            key: formKey,
            value: {
                lat: value[0],
                lon: value[1]
            }
        })
    }

    return (
        <>
            <Typography
                variant={"subtitle1"}
            >
                Plassering i kart
            </Typography>
            <Stack
                direction={"row"}
                alignItems={'center'}
                justifyContent={"center"}
                spacing={2}
                sx={{
                    margin: 2
                }}
            >
                <TextField
                    id="search"
                    label="Søk i kart"
                    variant="filled"
                    value={searchInput}
                    fullWidth={true}
                    onChange={event => setSearchInput(event.target.value)}
                    onKeyPress={ev => {
                        if("Enter" === ev.key) {
                            handleSearch(searchInput)
                        }
                    }}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <Button
                    variant={"contained"}
                    color={"secondary"}
                    onClick={() => handleSearch(searchInput)} type={"submit"}
                >
                    Søk
                </Button>
            </Stack>
            <MapContainer center={[65, 11]} zoom={zoom} className={classes.mapContainer}>
                <TileLayer
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <AdminMap position={position} clickHandler={({lat, lng}) => onMarkerSet([lat, lng])}/>
                {getMarker()}
            </MapContainer>
        </>
    )
}