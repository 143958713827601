import React from 'react'
import {Paper, Stack, TableContainer, useMediaQuery} from "@mui/material";
import {useFirebaseState} from "../firebase/firebaseContext";
import {useIsInstructorOrAdmin} from "../firebase/useIsInstructorOrAdmin";
import {FoldersTable} from "./FoldersTable";
import {FolderProvider} from "./folderContext";
import {UploadFileOverlay} from "./UploadFileOverlay";
import {ActiveDropTarget} from "./ActiveDropTarget";
import {FileDetails} from "./FileDetails";
import {FileDetailsDialog} from "./FileDetailsDialog";
import { useTheme } from '@mui/material/styles';
import {FileDetailsSideSheet} from "./FileDetailsSideSheet";

export const Folders = () => {
    const {auth} = useFirebaseState()
    const instructorOrAdmin = useIsInstructorOrAdmin()
    const theme = useTheme()
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

    const detailsView = lgUp
        ? (
            <FileDetailsSideSheet>
                <FileDetails />
            </FileDetailsSideSheet>
        )
        : (
            <FileDetailsDialog>
                <FileDetails />
            </FileDetailsDialog>
        )

    if(!auth || !auth.currentUser || !instructorOrAdmin) {
        return null
    } else {
        return (
            <FolderProvider>
                <UploadFileOverlay>
                    <Stack
                        direction={"row"}
                        spacing={2}
                    >
                        <TableContainer
                            component={Paper}
                            sx={{
                                minHeight: "90vh",
                                mb: 4
                            }}
                        >
                            <FoldersTable />
                        </TableContainer>
                        {detailsView}
                    </Stack>
                </UploadFileOverlay>
                <ActiveDropTarget />
            </FolderProvider>
        )
    }
}