import React from 'react';
import {News} from "../news/News";
import {Container} from "@mui/material";

export const LandingPage = () => {

    return (
        <Container
            maxWidth={"md"}
            disableGutters={true}
        >
            <News />
        </Container>
    );
}