import {useFolderState} from "./folderContext";

export const useActiveDropTarget = () => {
    const {dropTarget} = useFolderState()

    if(dropTarget.length === 1) {
        return dropTarget[0]
    } else if(dropTarget.length === 0) {
        return null
    } else {
        return dropTarget.find(target => !target.parentTarget)
    }
}