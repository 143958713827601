import React, {useState} from 'react';
import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemText from "@mui/material/ListItemText";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {InstructorDetails} from "./InstructorDetails";
import {usePublishedInstructors} from "../firebase/usePublishedInstructors";
import {ListItemButton} from "@mui/material";

export const InstructorsList = () => {
    const [active, setActive] = useState(null);
    const [selected, setSelected] = useState(null);
    const instructors = usePublishedInstructors({
        orderBy: "county",
        order: "asc"
    })

    const counties = [...new Set(instructors.map(instructor => instructor.county.trim()))];

    const getInstructorsByCounty = county => instructors
        .filter(instructor => county === instructor.county.trim())
        .sort((a, b) => a.name.localeCompare(b.name))

    return (
        <>
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    height: "100%",
                    backgroundColor: theme => theme.palette.background.paper,
                }}
            >
                {counties.map(county => (
                    <div key={county}>
                        <ListItemButton
                            onClick={() => setActive(active => active === county ? null : county)}
                        >
                            <ListItemText>
                                {county} ({getInstructorsByCounty(county).length})
                            </ListItemText>
                            {county === active ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={county === active} timeout={"auto"} unmountOnExit={true}>
                            <List
                                sx={{
                                    pl: 4
                                }}
                            >
                                {getInstructorsByCounty(county).map(instructor => (
                                    <ListItemButton
                                        key={instructor.id}
                                        onClick={() => setSelected(instructor.id)}
                                    >
                                        <ListItemText>
                                            {instructor.name} ({instructor.place})
                                        </ListItemText>
                                    </ListItemButton>
                                ))
                                }
                            </List>
                        </Collapse>
                    </div>
                ))}
            </List>
            <InstructorDetails
                handleClose={() => setSelected(null)}
                instructor={instructors.find(instructor => selected === instructor.id)}
            />
        </>
    )
}