import React from 'react';
import {UserAvatar} from "./UserAvatar";
import {useFirebaseState} from "../firebase/firebaseContext";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

export const UserButton = () => {
    const {auth} = useFirebaseState()
    const navigate = useNavigate()

    if(auth && auth.currentUser) {
        return (
            <UserAvatar
                user={auth.currentUser}
            />
        )
    } else {
        return (
            <Button
                color="inherit"
                onClick={() => navigate('/login')}
            >
                Logg på
            </Button>
        )
    }
}