import {useFolders} from "./useFolders";
import {Divider, Table} from "@mui/material";
import {FoldersTableHead} from "./FoldersTableHead";
import {useState} from "react";
import {FoldersTableBody} from "./FoldersTableBody";
import {FoldersToolbar} from "./FoldersToolbar";
import {DESELECTED, useFolderDispatch, useFolderState} from "./folderContext";

export const FoldersTable = () => {
    const {
        forceRefresh,
        parentFolders
    } = useFolderState()

    const folderDispatch = useFolderDispatch()

    const [paging, setPaging] = useState({
        order: 'asc',
        orderBy: 'title',
        folderId: null
    })
    const folders = useFolders({
        orderBy: paging.orderBy,
        order: paging.order,
        folderIds: parentFolders.map(folder => folder.id),
        forceRefresh: forceRefresh
    })

    const onSort = order => {
        folderDispatch({type: DESELECTED})
        setPaging(p => ({
            ...p,
            ...order
        }))
    }

    return (
        <>
            <FoldersToolbar />
            <Divider />
            <Table
                aria-label={"Filer og mapper"}
            >

                <FoldersTableHead
                    order={paging.order}
                    orderBy={paging.orderBy}
                    sortHandler={onSort}
                />
                <FoldersTableBody
                    rows={folders}
                />
            </Table>
        </>
    )
}