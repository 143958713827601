import React from "react";
import {useFirebaseState} from "../firebase/firebaseContext";
import {signInWithEmailAndPassword} from "firebase/auth";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {EmailPasswordForm} from "./EmailPasswordForm";

export const LoginUsernamePassword = () => {
    const {auth} = useFirebaseState()
    const [loginFailed, setLoginFailed] = React.useState(false)

    const handleLogin = formData => {
        signInWithEmailAndPassword(auth, formData.username, formData.password)
            .then(() => {
                console.debug("Login successful")
            })
            .catch(error => {
                console.error({error})
                setLoginFailed(true)
            })
    }

    const validationSchema = Yup.object().shape({
        username: Yup.string()
            .email('Må være en gyldig epostadresse')
            .required('Skriv inn e-posten din'),
        password: Yup.string()
            .required('Skriv inn passordet ditt')
    })

    return (
        <FormProvider
            schema={validationSchema}
            onSubmit={handleLogin}
        >
            <EmailPasswordForm
                loginFailed={loginFailed}
            />
        </FormProvider>

    )
}