import {Box} from "@mui/material";
import {useCallback, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {DRAG_TARGET_DESELECTED, DRAG_TARGET_SELECTED, useFolderDispatch, useFolderState} from "./folderContext";
import {useFirebaseFileUpload} from "../firebase/useFirebaseFileUpload";

const parentDropTarget = folder => {
    if(folder) {
        return {
            ...folder,
            parentTarget: true
        }
    } else {
        return {
            parentTarget: true,
            title: "Filer",
            id: 0
        }
    }
}

export const UploadFileOverlay = ({children}) => {
    const {parentFolders} = useFolderState()
    const folderDispatch = useFolderDispatch()
    const folder = parentFolders.slice(-1)[0]
    const {uploadFile} = useFirebaseFileUpload({})

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach(file => {
                uploadFile(file)
            })
        },
        [parentFolders]
    )

    const {
        getRootProps,
        isDragActive,
    } = useDropzone({
        onDrop: onDrop,
        noClick: true,
    })

    useEffect(() => {
        if(isDragActive) {
            folderDispatch({
                type: DRAG_TARGET_SELECTED,
                dropTarget: parentDropTarget(folder)
            })
        } else {
            folderDispatch({
                type: DRAG_TARGET_DESELECTED,
                dropTarget: parentDropTarget(folder)
            })
        }
    }, [isDragActive, folderDispatch, folder])


    return (
        <Box
            {...getRootProps()}
            sx={{
                borderStyle: isDragActive ? "solid" : "none",
                height: "100%"
            }}
        >
            {children}
        </Box>
    )
}