import React from "react";
import {FormProvider} from "../form/formContext";
import * as Yup from "yup";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {Delete} from "@mui/icons-material";
import {FormSubmit} from "../form/FormSubmit";
import {FolderForm} from "./FolderForm";
import {collection, deleteDoc, doc} from "firebase/firestore";
import {useFirebaseState} from "../firebase/firebaseContext";
import {PARENT_FOLDERS_SET, REFRESH_FORCED, useFolderDispatch, useFolderState} from "./folderContext";
import {getStorage, ref, deleteObject, listAll} from "firebase/storage";

export const DeleteFolder = ({open, onClose, folder=null, folderIsFile=false}) => {
    const {db} = useFirebaseState()
    const {
        parentFolders
    } = useFolderState()

    const folderIdsParams = parentFolders
        .slice(0, folder ? parentFolders.length : -1)
        .map(folder => [folder.id, 'folders']).flat(1)
    const deleteFolder = folder ? folder : parentFolders.slice(-1)[0]
    const folderDispatch = useFolderDispatch()

    const filesPath = '/files/' + [...parentFolders, folder]
        .filter(folder => null !== folder)
        .map(folder => folder.title)
        .join('/')
        .concat('/')

    const deleteStorageObject = item => {
        return deleteObject(item)
            .then(() => {
                return Promise.resolve()
            })
            .catch(error => {
                console.error(error)
                return Promise.resolve()
            })
    }

    const deleteStorageItem = item => {
        if(folderIsFile) {
            return deleteStorageObject(item)
        } else {
            return listAll(item)
                .then(res => {
                    return Promise.all([
                        ...res.prefixes.map(deleteStorageItem),
                        ...res.items.map(deleteStorageObject)
                    ])
                })
        }

    }

    const handleDelete = () => {
        const storage = getStorage()
        const filesRef = ref(storage, filesPath)
        deleteStorageItem(filesRef)
            .then(() => {
                const foldersRef = collection(db, 'folders', ...folderIdsParams);
                deleteDoc(doc(foldersRef, deleteFolder.id))
                    .then(() => {
                        onClose()
                        if(folder) {
                            folderDispatch({
                                type: REFRESH_FORCED
                            })
                        } else {
                            folderDispatch({
                                type: PARENT_FOLDERS_SET,
                                parentFolders: parentFolders.slice(0, -1)
                            })
                        }

                    })
                    .catch(error => console.error(error))
            })
    }

    const validationSchema = () =>  {
        if(open) {
            return Yup.object().shape({
                title: Yup.string()
                    .required(`Skriv inn navnet på ${folderIsFile ? "filen" : "mappen"}`)
                    .oneOf([deleteFolder.title, null],
                        `Skriv inn det nøyaktige navnet på ${folderIsFile ? "filen" : "mappen"} for å slette den.`
                    )

            })
        } else {
            return null
        }

    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth={true}
        >
            <FormProvider
                schema={validationSchema()}
                onSubmit={handleDelete}
            >
                <DialogTitle>
                    Slett mappe
                </DialogTitle>
                <DialogContent>
                    Skriv inn navnet på {folderIsFile ? "filen" : "mappen"} ({deleteFolder?.title}) for å slette den{!folderIsFile ? " og alt dens innhold" : ""}.
                    <FolderForm
                        folderIsFile={folderIsFile}
                    />
                </DialogContent>
                <DialogActions>
                    <FormSubmit
                        disabled={false}
                        variant={"contained"}
                        startIcon={<Delete />}
                    >
                        Slett
                    </FormSubmit>
                </DialogActions>
            </FormProvider>
        </Dialog>
    )
}